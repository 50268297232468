export const RECEIVE_STATUS_CODE = 'RECEIVE_STATUS_CODE'

export function receiveStatusCode(statusCode) {
    if (statusCode){
        return {
            type: RECEIVE_STATUS_CODE,
            statusCode
        }
    } else {
        return {
            type: RECEIVE_STATUS_CODE,
            statusCode: null
        }
    }
}

export function handleSetStatusCode(statusCode) {
    return (dispatch) => {
        dispatch(receiveStatusCode(statusCode))
    }
}