import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Button from 'react-bootstrap/Button'
import video from '../../styles/videos/ftpLaunchVideo.mp4';
import { 
    ContentAltContainer, 
    ContentWrapper, 
    Section, 
    SectionColumnOne, 
    SectionColumnTwo, 
    SectionColumnOneContentContainer, 
    TitleContainer, 
    BodyContainer, 
    ButtonContainer } from './HomePage';
import styled from 'styled-components';
import { size } from '../../styles/style';

export const Video = styled.video`
    max-width: 100%;
    height: auto;
    margin: 0em;

    @media screen and (max-width: ${size.laptop}) {
        & {
            width: 10em;
            height: 20em;
        }
    }

    @media screen and (max-width: ${size.tablet}) {
        & {
            width: 100%;
        }
    }
`;

class SectionTwo extends Component {
    render() {
        return (
            <ContentAltContainer className='content-alt'>
                <ContentWrapper className='content-wrapper'>
                    <Section className='section section-two'>
                        <SectionColumnOne className='section-column-one'>
                            <SectionColumnOneContentContainer className='section-column-one-content-container'>
                                <TitleContainer className='title-container'>
                                    <h1> What is FlipThePips? </h1>
                                </TitleContainer>
                                <BodyContainer className='body-container'>
                                    <p>
                                    FlipThePips is a revolutionary AI-powered fully automated and secure trading platform. 
                                    Take your trading experience to a new level and maximize your profits with minimal efforts.
                                    </p>
                                </BodyContainer>
                                <ButtonContainer className='button-container'>
                                    <NavLink to='/about-us' exact>
                                        <Button variant="dark" size="lg"> Learn More </Button>
                                    </NavLink>
                                </ButtonContainer>
                            </SectionColumnOneContentContainer>
                        </SectionColumnOne>
                        <SectionColumnTwo className='section-column-two'>
                            <Video className='video' controls>
                                <source src={video} type="video/mp4" />
                            </Video>
                        </SectionColumnTwo>
                    </Section>
                </ContentWrapper>
            </ContentAltContainer>
        );
    }
}

export default SectionTwo;