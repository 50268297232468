import { NavLink } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { CgMenu, CgClose } from 'react-icons/cg';
import Links from './Links';
import Logo from './Logo';
import { useState } from 'react';
import { NavLeft, NavCenter, NavRight, NavRightItem } from './Navigation';
import styled from 'styled-components';
import { size } from '../../styles/style';

const MobileNavBar = styled.div`
    @media screen and (min-width: 769px) {
        & {
            display: none;
          }
    }

    @media screen and (max-width: ${size.tablet}) {
        display: flex;
        justify-content: space-between;
    }
`;



const MobileNavigation = () => {

    const [open, setOpen] = useState(false);

    const hamburgerIcon = <CgMenu size='1.5em' onClick={() => setOpen(!open)} />

    const closeIcon = <CgClose size='1.5em' onClick={() => setOpen(!open)} />

    const closeMobileMenu = () => setOpen(false)

    return (
        <MobileNavBar className='nav-wrapper mobile-navigation'>
            <NavLeft className='nav-left'>
                <Logo />
            </NavLeft>
            <NavCenter className='nav-center'>
            </NavCenter>
            <NavRight className='nav-right'>
                <NavRightItem className='nav-right-item sign-up'>
                    <NavLink to='/signup' exact>
                        <Button variant="dark" size="md"> Sign Up </Button>
                    </NavLink>
                </NavRightItem>
            <NavRightItem className='nav-right-item menu'>
                {open ? closeIcon : hamburgerIcon}
                {open && <Links mobile={true} closeMobileMenu={closeMobileMenu} />}
            </NavRightItem>
            </NavRight>
        </MobileNavBar>
    );
}

export default MobileNavigation