const url = 'https://api.flipthepips.com';
// const url = 'http://localhost:3001';

// Trade Sync Data API Calls
export const getBrokers = async () => {
    let isLoading = true
    try {
        const res = await fetch(`${url}/api/v1/brokers`);
        if(!res.ok) throw new Error('Data not fetched');
        const data = await res.json();
        return data
    } catch (err) {
        console.warn(err)
        return []
    } finally {
        isLoading = false;
    }
}

export const getBrokerServers = async (id = null,mtVersion = null) => {
    let res;
    let isLoading = true
    try {
        if (id) {
            res = await fetch(`${url}/api/v1/broker-servers?id=${id}`);
        } else {
            res = await fetch(`${url}/api/v1/broker-servers?mt_version=${mtVersion}`);
        }

        if(!res.ok) throw new Error('Data not fetched');
        const data = await res.json();
        return data
    } catch (err) {
        console.warn(err)
        return []
    } finally {
        isLoading = false;
    }
}

export const getAccount = async (token,accountId) => {
    let isLoading = true
    try{
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const response = await fetch(`${url}/api/v1/account/${accountId}`, requestOptions);
        
        return response
    } catch (err) {
        console.warn(err)
    } finally {
        isLoading = false
    }
}

export const getOpenTrades = async (token,accountId) => {
    let isLoading = true
    try{
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const response = await fetch(`${url}/api/v1/account/${accountId}/trades`, requestOptions);
        if(!response.ok) throw new Error('Data not fetched');
        const data = await response.json();
        return data
    } catch (err) {
        console.warn(err)
    } finally {
        isLoading = false
    }
}

export const getClosedTrades = async (token,accountId) => {
    let isLoading = true
    try{
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const response = await fetch(`${url}/api/v1/account/${accountId}/history`, requestOptions);
        if(!response.ok) throw new Error('Data not fetched');
        const data = await response.json();
        return data
    } catch (err) {
        console.warn(err)
    } finally {
        isLoading = false
    }
}

// Custom FTP Aggregate Function API Calls
export const getPublicAccountBalance = async (period) => {
    let isLoading = true
    try {
        const res = await fetch(`${url}/api/v1/performance/balance?period=${period}`)
        if(!res.ok) throw new Error('Data not fetched');
        const data = await res.json();

        return data
    } catch (err) {
        console.warn(err)
        return 0
    } finally {
        isLoading = false
    }
}

export const getPublicAccountAnalysis = async () => {
    let isLoading = true
    try {
        const res = await fetch(`${url}/api/v1/performance/analysis`)
        if(!res.ok) throw new Error('Data not fetched');
        const data = await res.json();
        return data
    } catch (err) {
        console.warn(err)
        return {}
    } finally {
        isLoading = false
    }
}

export const getAccountBalance = async (accountId,token,period) => {
    let isLoading = true
    try {
        const requestOptions = {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }

        const res = await fetch(`${url}/api/v1/account/${accountId}/balance?period=${period}`,requestOptions)
        if(!res.ok) throw new Error('Data not fetched');
        const data = await res.json();

        return data
    } catch (err) {
        console.warn(err)
        return 0
    } finally {
        isLoading = false
    }
}

export const getAccountAnalysis = async (accountId,token) => {
    let isLoading = true
    try {
        const requestOptions = {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }

        const res = await fetch(`${url}/api/v1/account/${accountId}/analysis`,requestOptions)
        if(!res.ok) throw new Error('Data not fetched');
        const data = await res.json();
        return data

    } catch (err) {
        console.warn(err)
        return []
    } finally {
        isLoading = false
    }
}

// Authentication API Calls
export const signIn =  async(username,password) => {
    let isLoading = true
    try{
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
            { 
                username, 
                password 
            })
        };
        const response = await fetch(`${url}/api/v1/signin`, requestOptions);

        return response
    } catch (err) {
        console.warn(err)
    } finally {
        isLoading = false
    }
}

export const signUp = async(username,email,password,mtVersion,accountNumber,accountPassword,brokerServerId) => {
    let isLoading = true
    try{
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                username,
                email,
                password,
                mtVersion,
                accountNumber,
                accountPassword,
                brokerServerId
            })
        }
        const response = await fetch(`${url}/api/v1/signup`, requestOptions);
        
        return response
    } catch (err) {
        console.warn(err)
    } finally {
        isLoading = false
    }
}

export const refreshToken = async (token) => {
    let isLoading = true
    try{
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const response = await fetch(`${url}/api/v1/refresh-token`, requestOptions);
        
        return response
    } catch (err) {
        console.warn(err)
    } finally {
        isLoading = false
    }
}

// Stripe Payment Management API Calls
export const createCardToken = async (token,name,cardNumber,expiryDate,cvv,zip) => {
    let isLoading = true
    let expMonth = expiryDate.split('/')[0].trim()
    let expYear = expiryDate.split('/')[1].trim()
    try{
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                name,
                cardNumber,
                expMonth,
                expYear,
                cvv,
                zip
            })
        }
        const response = await fetch(`${url}/api/v1/card/token`, requestOptions);
        
        return response
    } catch (err) {
        console.warn(err)
    } finally {
        isLoading = false
    }
}

export const createSubscription = async (token,customerId,paymentToken) => {
    let isLoading = true
    try{
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                customerId,
                token: paymentToken
            })
        }
        const response = await fetch(`${url}/api/v1/subscription`, requestOptions);
        
        return response
    } catch (err) {
        console.warn(err)
    } finally {
        isLoading = false
    }
}

export const getSubscription = async (token,subsciptionId) => {
    let isLoading = true
    try{
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const response = await fetch(`${url}/api/v1/subscription?subId=${subsciptionId}`, requestOptions);
        
        return response
    } catch (err) {
        console.warn(err)
    } finally {
        isLoading = false
    }
}

export const getCustomer = async (token,customerId) => {
    let isLoading = true
    try{
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const response = await fetch(`${url}/api/v1/customer?cusId=${customerId}`, requestOptions);

        return response
    } catch (err) {
        console.warn(err)
    } finally {
        isLoading = false
    }
}

export const getCard = async (token,customerId,cardId) => {
    let isLoading = true
    try{
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const response = await fetch(`${url}/api/v1/card?cusId=${customerId}&cardId=${cardId}`, requestOptions);
        if(!response.ok) throw new Error('Data not fetched');
        const data = await response.json();
        return data
    } catch (err) {
        console.warn(err)
    } finally {
        isLoading = false
    }
}

export const getPrice = async (priceId) => {
    let isLoading = true
    try {
        const res = await fetch(`${url}/api/v1/price?priceId=${priceId}`)
        if(!res.ok) throw new Error('Data not fetched');
        const data = await res.json();

        return data
    } catch (err) {
        console.warn(err)
    } finally {
        isLoading = false
    }
}

export const createNewCardFromToken = async (token,customerId,cardToken) => {
    let isLoading = true
    try{
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                customerId,
                token: cardToken
            })
        }
        const response = await fetch(`${url}/api/v1/card`, requestOptions);
        
        return response
    } catch (err) {
        console.warn(err)
    } finally {
        isLoading = false
    }
}

export const updateCustomerDefaultSource = async (token,customerId,defaultSource) => {
    let isLoading = true
    try{
        const requestOptions = {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                customerId,
                defaultSource
            })
        }
        const response = await fetch(`${url}/api/v1/customer`, requestOptions);
        
        return response
    } catch (err) {
        console.warn(err)
    } finally {
        isLoading = false
    }
}

export const pauseSubscription = async (token,accountId,pause,pauseDuration) => {
    let isLoading = true
    try{
        const requestOptions = {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                id: accountId,
                pause,
                pauseDuration
            })
        }
        const response = await fetch(`${url}/api/v1/account/pause`, requestOptions);
        
        return response
    } catch (err) {
        console.warn(err)
    } finally {
        isLoading = false
    }
}

export const cancelSubscription = async (token,accountId,cancel) => {
    let isLoading = true
    try{
        const requestOptions = {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                id: accountId,
                cancel
            })
        }
        const response = await fetch(`${url}/api/v1/account/cancel`, requestOptions);
        
        return response
    } catch (err) {
        console.warn(err)
    } finally {
        isLoading = false
    }
}

// Account Information id = userId ≠ accountId
export const getUserInformation = async (token,id) => {
    let isLoading = true
    try{
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const response = await fetch(`${url}/api/v1/account/${id}/settings`, requestOptions);
        
        return response
    } catch (err) {
        console.warn(err)
    } finally {
        isLoading = false
    }
}

export const getHoldingUserInformation = async (token,id) => {
    let isLoading = true
    try{
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }
        const response = await fetch(`${url}/api/v1/account/holding/${id}/settings`, requestOptions);
        
        return response
    } catch (err) {
        console.warn(err)
    } finally {
        isLoading = false
    }
}

export const updateUserEmail = async (token,email,confirmedEmail) => {
    let isLoading = true
    try{
        const requestOptions = {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                email,
                confirmedEmail
            })
        }
        const response = await fetch(`${url}/api/v1/account/email`, requestOptions);
        
        return response
    } catch (err) {
        console.warn(err)
    } finally {
        isLoading = false
    }
}

export const updateUserPassword = async (token,currentPassword,password,confirmedPassword) => {
    let isLoading = true
    try{
        const requestOptions = {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                currentPassword,
                password,
                confirmedPassword
            })
        }
        const response = await fetch(`${url}/api/v1/account/password`, requestOptions);
        
        return response
    } catch (err) {
        console.warn(err)
    } finally {
        isLoading = false
    }
}

export const updateAccountInformtaion = async (token,id,accountNumber,accountPassword,mtVersion,brokerServerId) => {
    let isLoading = true
    try{
        const requestOptions = {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                id,
                accountNumber,
                accountPassword,
                mtVersion,
                brokerServerId
            })
        }
        const response = await fetch(`${url}/api/v1/account/settings`, requestOptions);
        
        return response
    } catch (err) {
        console.warn(err)
    } finally {
        isLoading = false
    }
}

export const sendPasswordResetEmail = async (email) => {
    let isLoading = true
    try{
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email
            })
        }
        const response = await fetch(`${url}/api/v1/forgot`, requestOptions);
        
        return response
    } catch (err) {
        console.warn(err)
    } finally {
        isLoading = false
    }
}

export const resetPasswordUnauth = async (token,id,holding,password) => {
    let isLoading = true
    try{
        const requestOptions = {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                password
            })
        }
        const response = await fetch(`${url}/api/v1/password-reset?token=${token}&id=${id}&holding=${holding}`, requestOptions);
        
        return response
    } catch (err) {
        console.warn(err)
    } finally {
        isLoading = false
    }
}

// Support
export const sendSupportEmail = async (name,email,subject,message) => {
    let isLoading = true
    try{
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name,
                email,
                subject,
                message
            })
        }
        const response = await fetch(`${url}/api/v1/support`, requestOptions);
        
        return response
    } catch (err) {
        console.warn(err)
    } finally {
        isLoading = false
    }
}