import React, { Component } from 'react';
import { Container,ContentWrapper,HeaderWrapper,FormContainer,InlineForm,InputWrappers,FormInputs } from './style';
import { Button, Alert, Form } from 'react-bootstrap';
import { resetPasswordUnauth } from '@ftp/controllers';

class PasswordReset extends Component {
    constructor (props) {
        super()
        this.state = {
            token: '',
            id: '',
            holding: '',
            password: '',
            confirmedPassword: '',
            statusCode: '',
            show: false,
            loading: false
        };

        this.handleSubmit = this.handleSubmit.bind(this)
    }

    setPassword = (password) => {
        this.setState({ password })
    }

    setConfirmedPassword = (confirmedPassword) => {
        this.setState({ confirmedPassword })
    }

    setShow = (show) => {
        this.setState({ show })
    }

    handleSubmit = async(e) => {
        e.preventDefault();
        this.setState({ loading: true })
        const token = this.state.token
        const id = this.state.id
        const holding = this.state.holding
        const password = this.state.password
        const confirmedPassword = this.state.confirmedPassword
        if (password !== confirmedPassword) {
            this.setShow(true)
        } else {
            const passwordReset = await resetPasswordUnauth(token,id,holding,password)
            if (passwordReset.status === 200) {
                this.props.history.push('/signin')
            }
        }
        this.setState({ loading: false })
    }

    componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search)
        const token = queryParams.get('token')
        this.setState({ token: token })

        const id = queryParams.get('id')
        this.setState({ id: id })

        const holding = queryParams.get('holding')
        this.setState({ holding: holding })
    }

    render() {
        return(
            <Container className='sign-in-container'>
                <ContentWrapper>
                    { this.state.password !== this.state.confirmedPassword && this.state.show ?
                    (<Alert variant='danger' onClose={() => this.setShow(false)} dismissible>Passwords Do Not Match.</Alert>) :
                    (<Alert variant='danger' style={{visibility: 'hidden'}}> Hidden </Alert>) }
                </ContentWrapper>
                <ContentWrapper className='content-wrapper'>
                    <FormContainer className='form-container'>
                        <HeaderWrapper>
                            <h2> Create A New Password </h2>
                        </HeaderWrapper>
                        <InlineForm onSubmit={this.handleSubmit}>
                            <InputWrappers>
                                <span>
                                Please create a new password below. For you account security we advise your password is at least six characters,
                                <br />
                                includes at least one number or special character, and is not a commonly used password.
                                </span>
                            </InputWrappers>
                            <br />
                            <InputWrappers>
                                <Form.Label>New Password</Form.Label>
                                <FormInputs type={'password'} placeholder={'New Password'} value={this.state.password || ''} onChange={e => this.setPassword(e.target.value)} />
                            </InputWrappers>
                            <InputWrappers>
                                <Form.Label> Confirm New Password</Form.Label>
                                <FormInputs type={'password'} placeholder={'New Password'} value={this.state.confirmedPassword || ''} onChange={e => this.setConfirmedPassword(e.target.value)} />
                            </InputWrappers>
                            <br />
                            <InputWrappers className='d-grid gap-2'>
                                <Button type={'submit'} 
                                        disabled={(this.state.password !== this.state.confirmedPassword) || (this.state.password === '' ||  this.state.confirmedPassword === '') || this.state.loading}
                                        variant='dark'>
                                            {this.state.loading ? 'Submitting...' : 'Submit'}
                                </Button>
                            </InputWrappers>
                        </InlineForm>
                    </FormContainer>
                </ContentWrapper>
            </Container>
        )
    }
}

export default PasswordReset