import { receiveToken } from './token';
import { receiveStatusCode } from './statusCode';
import { receiveUsername } from './username';
import { receivePrivateMode } from './privateMode';

export function handleInitialData() {
    return (dispatch, getState) => {
        const { token,statusCode,username,privateMode } = getState()
        dispatch(receiveToken(token))
        dispatch(receiveStatusCode(statusCode))
        dispatch(receiveUsername(username))
        dispatch(receivePrivateMode(privateMode))
    }
}