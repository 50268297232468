import React, { Component } from 'react';
import { Container,ContentWrapper,HeaderWrapper,FormContainer,InlineForm,InputWrappers,FormInputs } from './style';
import { Button, Alert, Form } from 'react-bootstrap';
import { sendPasswordResetEmail } from '@ftp/controllers';

class Forgot extends Component {
    constructor (props) {
        super()
        this.state = {
            email: '',
            statusCode: '',
            show: false,
            loading: false
        };

        this.handleSubmit = this.handleSubmit.bind(this)
    }

    setEmail = (email) => {
        this.setState({ email })
    }

    setShow = (show) => {
        this.setState({ show })
    }

    handleSubmit = async(e) => {
        e.preventDefault();
        this.setState({ loading: true })
        const email = this.state.email
        const passwordReset = await sendPasswordResetEmail(email)
        this.setState({ statusCode: passwordReset.status })
        this.setShow(true)
        this.setEmail('')
        this.setState({loading: false})
    }

    render() {
        return(
            <Container className='sign-in-container'>
                <ContentWrapper>
                    { this.state.statusCode === 200 && this.state.show ?
                    (<Alert variant='success' onClose={() => this.setShow(false)} dismissible>Reset Email Sent.</Alert>) :
                    this.state.statusCode === 404 && this.state.show ? 
                    (<Alert variant='danger' onClose={() => this.setShow(false)} dismissible> Email Not Found.</Alert>) : 
                    (<Alert variant='danger' style={{visibility: 'hidden'}}> Hidden </Alert>) }
                </ContentWrapper>
                <ContentWrapper className='content-wrapper'>
                    <FormContainer className='form-container'>
                        <HeaderWrapper>
                            <h2> Forgot Password? </h2>
                        </HeaderWrapper>
                        <InlineForm onSubmit={this.handleSubmit}>
                            <InputWrappers>
                                <span>
                                Enter the email address you use for FlipThePips and we'll send you a password reset link.
                                </span>
                            </InputWrappers>
                            <br />
                            <InputWrappers>
                                <Form.Label>Email</Form.Label>
                                <FormInputs type={'email'} placeholder={'Enter Email'} value={this.state.email || ''} onChange={e => this.setEmail(e.target.value)} />
                            </InputWrappers>
                            <InputWrappers className='d-grid gap-2'>
                                <Button type={'submit'} 
                                        disabled={(this.state.email === '') || this.state.loading}
                                        variant='dark'>
                                            {this.state.loading ? 'Sending Reset Link...' : 'Send Reset Link'}
                                </Button>
                            </InputWrappers>
                        </InlineForm>
                    </FormContainer>
                </ContentWrapper>
            </Container>
        )
    }
}

export default Forgot