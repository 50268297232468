import React, { Component } from 'react';
import { AccountCard } from './style';
import { Button,Row } from 'react-bootstrap';
import PauseSubscription from './Modals/PauseSubscription';

class Pause extends Component {
    constructor () {
        super()
        this.state = {
            showPauseModal: false
        };
    }

    setShowPauseModal = (showPauseModal) => {
        this.setState({ showPauseModal })
    }

    handleClosePause = () => this.setShowPauseModal(false)
    handleShowPause = () => this.setShowPauseModal(true)

    render() {
        return(
            <AccountCard>
                <Row>
                    <div className='account-content-two'>
                        <span className='account-header-two'> {!this.props.pause ? 'Pause Your Subscription' : 'Resume Your Subscription'}</span>
                        <div>
                            {!this.props.pause ? (
                                <Button className='new-btn-link' variant='link' onClick={this.handleShowPause}> Pause </Button>
                            ) : (
                                <Button className='new-btn-link' variant='link' onClick={this.props.resumeFromPause}> Resume </Button>
                            )}

                        </div>
                    </div>
                </Row>
                <PauseSubscription showModal={this.state.showPauseModal} handleClose={this.handleClosePause}/>
            </AccountCard>
        )
    }
}

export default Pause