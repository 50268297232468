import React, { Component } from 'react';
import { AccountCard,TextContainer,PaymentMethod } from './style';
import { Button } from 'react-bootstrap';
import ChangeEmail from './Modals/ChangeEmail';
import ChangePassword from './Modals/ChangePassword';
import UpdateAccount from './Modals/UpdateAccount';

class AccountInformation extends Component {
    constructor () {
        super()
        this.state = {
            showAccountModal: false,
            showEmailModal: false,
            showPasswordModal: false
        };
    }

    setShowAccountModal = (showAccountModal) => {
        this.setState({ showAccountModal })


    }

    handleCloseAccount = () => this.setShowAccountModal(false)
    handleShowAccount = () => this.setShowAccountModal(true)

    setShowEmailModal = (showEmailModal) => {
        this.setState({ showEmailModal })
    }

    handleCloseEmail = () => this.setShowEmailModal(false)
    handleShowEmail = () => this.setShowEmailModal(true)

    setShowPasswordModal = (showPasswordModal) => {
        this.setState({ showPasswordModal })
    }

    handleClosePassword = () => this.setShowPasswordModal(false)
    handleShowPassword = () => this.setShowPasswordModal(true)

    render() {
        return(
            <AccountCard>
                <h1 className='account-header'>Your Account</h1>
                <TextContainer>
                    <PaymentMethod>
                        <div>
                            <span>MT{this.props.mtVersion} Account Information</span>
                        </div>
                        <div>
                            <Button className='new-btn-link' variant='link' onClick={this.handleShowAccount}>Update Account</Button>
                        </div>
                    </PaymentMethod>
                    <h6> {this.props.accountNumber} </h6>
                    <UpdateAccount 
                        showModal={this.state.showAccountModal} 
                        handleClose={this.handleCloseAccount} 
                        accountNumber={this.props.accountNumber}
                        accountPassword={this.props.accountPassword}
                        brokerServer={this.props.brokerServer}/>
                </TextContainer>
                <hr />
                <TextContainer>
                    <PaymentMethod>
                        <div>
                            <span>Email</span>
                        </div>
                        <div>
                            <Button className='new-btn-link' variant='link' onClick={this.handleShowEmail}>Change Email</Button>
                        </div>
                    </PaymentMethod>
                    <h6> {this.props.email} </h6>
                    <ChangeEmail 
                        showModal={this.state.showEmailModal} 
                        handleClose={this.handleCloseEmail} 
                        email={this.props.email}/>
                </TextContainer>
                <hr />
                <TextContainer>
                    <PaymentMethod>
                        <div>
                            <span>Password</span>
                        </div>
                        <div>
                            <Button className='new-btn-link' variant='link' onClick={this.handleShowPassword}>Change Password</Button>
                        </div>
                    </PaymentMethod>
                    <h6> •••••••••••••• </h6>
                    <ChangePassword 
                        showModal={this.state.showPasswordModal} 
                        handleClose={this.handleClosePassword}/>
                </TextContainer>
            </AccountCard>
        )
    }
}

export default AccountInformation