import { RECEIVE_USERNAME } from "../actions/username"

export default function username(state = null, action) {
    switch (action.type) {
        case RECEIVE_USERNAME:
            return action.username

        default:
            return state
    }
}