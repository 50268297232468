import React, {Component} from 'react';
import styled from "styled-components";

const BalanceContainer = styled.div`
    font-size: 3rem;
    position: relative;
`;

const AccountBalanceText = styled.div`
    font-size: 1.25rem;
    position: relative;
`;

const BalanceWrapper = styled.div`
  font-size: 3rem;
  position: relative;
`;

class Balance extends Component {
    constructor () {
        super()
        this.state = {
            balance: 0
        };
    }

    componentDidMount() {
        const data = this.props.data
        const selectedBalance = this.props.balance
        const activePoint = this.props.activePoint
        const inactivePoint = this.props.inactivePoint

        if (data.length !== 0 && data !== undefined) {
            try {
                const currentData = data[data.length-1]
                const balance = currentData.balance
                this.setState({ balance: balance })
            } catch {
                this.setState({ balance: 0 })
            }

            if (typeof selectedBalance === 'undefined' || selectedBalance === null) {
                try {
                    const currentData = data[data.length-1]
                    const balance = currentData.balance
                    this.setState({ balance: balance })
                } catch {
                    this.setState({ balance: 0 })
                }

            } else {
                const isActive = (typeof activePoint !== 'undefined' || activePoint !== null)
                const isDeactivted = (typeof inactivePoint === 'undefined' || inactivePoint === null || inactivePoint.length === 0)
                // console.log("Active Point:"+isActive)
                // console.log("Inactive Point:"+isDeactivted)
                if (isActive === true && isDeactivted === true) {
                    try {
                        const currentData = data[data.length-1]
                        const balance = currentData.balance
                        this.setState({ balance: balance })
                    } catch {
                        this.setState({ balance: 0 })
                    }

                }
                else if (isActive === true && isDeactivted === false) {
                    try {
                        const balance = selectedBalance
                        this.setState({ balance: balance })
                    } catch {
                        this.setState({ balance: 0 })
                    }
                    
                }
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const data = this.props.data
        const selectedBalance = this.props.balance
        const activePoint = this.props.activePoint
        const inactivePoint = this.props.inactivePoint

        // console.log(data)
        // console.log(selectedBalance)
        // console.log(activePoint)
        // console.log(inactivePoint)

        if (typeof selectedBalance === 'undefined' || selectedBalance === null) {
            if (prevProps.data !== data) {
                try {
                    const currentData = data[data.length-1]
                    const balance = currentData.balance
                    this.setState({ balance: balance })
                } catch {
                    this.setState({ balance: 0 })
                }
            }
        } else {
            const isActive = (typeof activePoint !== 'undefined' || activePoint !== null)
            const isDeactivted = (typeof inactivePoint === 'undefined' || inactivePoint === null || inactivePoint.length === 0)
            // console.log(prevProps.inactivePoint)
            // console.log(inactivePoint)
            // console.log(prevProps.activePoint)
            // console.log(activePoint)
            if (isActive === true && isDeactivted === true) {
                if (activePoint !== null && (prevProps.inactivePoint.length === 0  && inactivePoint.length === 0)) {
                    if (prevProps.balance !== selectedBalance) {
                        try {
                            const balance = selectedBalance
                            this.setState({ balance: balance })
                        } catch {
                            this.setState({ balance: 0 })
                        }
                        
                    }
                }
                else if (prevProps.inactivePoint !== inactivePoint) {
                    try{
                        const currentData = data[data.length-1]
                        const balance = currentData.balance
                        this.setState({ balance: balance })
                    } catch {
                        this.setState({ balance: 0 })
                    }
                }
            }
            else if (isActive === true && isDeactivted === false) {
                if (prevProps.balance !== selectedBalance) {
                    try {
                        const balance = selectedBalance
                        this.setState({ balance: balance })
                    } catch {
                        this.setState({ balance: 0 })
                    }
                }
            }
        }
    }

    render() {
        const formatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});
        return (
            <BalanceContainer>
                <AccountBalanceText>
                    Portfolio Balance
                </AccountBalanceText>
                <BalanceWrapper>
                    {formatter.format(this.state.balance)}
                </BalanceWrapper>
            </BalanceContainer>
        )
    }
}

export default Balance