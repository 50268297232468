export const RECEIVE_PRIVATE_MODE = 'RECEIVE_PRIVATE_MODE'

export function receivePrivateMode(privateMode) {
    if (privateMode){
        return {
            type: RECEIVE_PRIVATE_MODE,
            privateMode
        }
    } else {
        return {
            type: RECEIVE_PRIVATE_MODE,
            privateMode: false
        }
    }
}

export function handleSetPrivateMode(privateMode) {
    return (dispatch) => {
        dispatch(receivePrivateMode(privateMode))
    }
}