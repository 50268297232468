import React, { useState,useEffect } from 'react';
import styled from "styled-components";

const PeriodSelectorContainer = styled.div`
  height: 5rem;
  font-size: 3rem;
  position: relative;
`;

const StyledButton = styled.button`
    display: inline-block;
    padding: 12px 8px;
    cursor: pointer;
    background: none;
    border-width: 0px;
`;

const StyledSpan = styled.span`
    font-size: 24px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    ${props => props.period !== props.value ? "&:hover {color:red;};" : ""}

    color: ${props => props.period === props.value ? "blue" : "black"}
`;

const Button = (props) => {
    const handlePeriod = props.handlePeriod
    const handleLoading = props.handleLoading
    const value = props.value
    const selectedPeriod = props.period
    return (
        <StyledButton onClick={() => {
            handlePeriod(value)
            handleLoading(true)
            }}>
            <StyledSpan period={selectedPeriod} value={value}>
                {props.text}
            </StyledSpan>
        </StyledButton>
    );
}

const PeriodSelector = (props) => {
    const [period,handlePeriod] = useState ('month')
    const updatePeriod = props.updatePeriod

    const [loading,handleLoading] = useState(true)
    const updateLoading = props.updateLoading

    useEffect(() => { updatePeriod(period)}, [period,updatePeriod])
    useEffect(() => { updateLoading(loading)}, [loading,updateLoading])

    return(
        <PeriodSelectorContainer>
            <Button text={"1d"} value={"day"} period={period} handlePeriod={handlePeriod} handleLoading={handleLoading}/>
            <Button text={"1w"} value={"week"} period={period} handlePeriod={handlePeriod} handleLoading={handleLoading}/>
            <Button text={"1m"} value={"month"} period={period} handlePeriod={handlePeriod} handleLoading={handleLoading}/>
            <Button text={"1y"} value={"year"} period={period} handlePeriod={handlePeriod} handleLoading={handleLoading}/>
            <Button text={"all"} value={"all"} period={period} handlePeriod={handlePeriod} handleLoading={handleLoading}/>
        </PeriodSelectorContainer>
    );
}

export default PeriodSelector