import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
    background-color: #f7f7f7;
    min-height: 100vh;
`;

export const ContentWrapper = styled.div`
    padding: 1em;
    display: table;
    margin: 1em auto;
`;

export const ContentContainer = styled.div`
    margin 5em 22em
`;

export const ContentCard = styled.div`
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    background-color: #ffff;
    margin: 3em 10em;
    min-width: 30vw;
`;

export const Title = styled.div`
    width: 100%;
    text-align: center;
    padding: 1em;
`;

export const CardTitle = styled.div`
    width: 100%;
    text-align: center;
    padding: 1em;
    font-weight: bold;
`;

export const CardContent = styled.div`
    width: 100%;
    text-align: center;
    padding: 1em;
    font-weight: bold;
`;

export const CardMain = styled.div`
    font-size: 3rem;
`;

export const CardSub = styled.div`
    font-size: 1rem;
`;

export const ButtonContainer = styled.div`
    margin: 2em
`;

class Pricing extends Component {    
    render() {
        return(
            <Container>
                <ContentWrapper>
                    <ContentContainer>
                        <Title>
                            <h1>Become a FlipThePips Trading Member!</h1>
                        </Title>
                        <Title>
                            <p>Please note: We recommend an initial investment of $1000 and the monthly membership fee is non-negotiable.</p>
                        </Title>
                        <ContentCard>
                            <CardTitle style={{backgroundColor: '#212529'}}>
                                <span style={{color: '#ffff'}}>Most Popular</span>
                            </CardTitle>
                            <CardContent>
                                <CardMain>
                                    <span>$219</span>
                                </CardMain>
                                <CardSub>
                                    <span>Per Month</span>
                                </CardSub>
                                <ButtonContainer>
                                    <NavLink to='/signup' exact>
                                        <Button variant="dark" size="lg"> Join Now </Button>
                                    </NavLink>
                                </ButtonContainer>
                            </CardContent>
                        </ContentCard>
                    </ContentContainer>
                </ContentWrapper>
            </Container>
        )
    }
}

export default Pricing