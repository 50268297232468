import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { privateMapStateToProps } from '../MapStateToProps';
import { handleSetPrivateMode } from '../../actions/privateMode';
import { handleSetToken } from '../../actions/token';
import { getToken,setToken } from '../../App';
import { getCustomer, getCard, getSubscription, getUserInformation, getHoldingUserInformation, pauseSubscription, refreshToken, cancelSubscription } from '@ftp/controllers';
import jwt_decode from 'jwt-decode';
import { Spinner } from 'react-bootstrap';
import { Container,ContentWrapper,LoadingContainer,AccountInfoContainer,AccountWrapper,ColumnContainer } from './style';
import moment from 'moment';
import UpcomingCharges from './UpcomingCharges';
import PaymentInformation from './PaymentInformation';
import Pause from './Pause';
import Subscription from './Subscription';
import Cancel from './Cancel';
import AccountInformation from './AccountInformation';
import { Buffer } from 'buffer';
import './style.css';

const timeStampToDate = (value) => {
    return moment.unix(value).format('MM/DD/YYYY');
}

class Account extends Component {
    constructor (props) {
        super()
        this.state = {
            token: '',
            holding: false,
            active: false,
            last4: '',
            brand: '',
            currentPeriodEnd: '',
            amount: '',
            email: '',
            password: '',
            mtVersion: '',
            accountNumber: '',
            accountPassword: '',
            broker: '',
            brokerServer: '',
            pause: false,
            pauseDate: '',
            pauseEndDate: '',
            cancel: false,
            cancelDate: '',
            loading: false
        };

        props.dispatch(handleSetPrivateMode(true))
    }

    getTokenObject = async (response) => {
        const data = await response.json()
        const tokenString = JSON.stringify(data)
        const token = JSON.parse(tokenString)

        return token
    }

    refreshPage = () => {
        window.location.reload(false);
    }

    resumeFromPause = async (e) => {
        e.preventDefault();
        this.setState({ loading: true })
        const token = this.state.token
        const decode = jwt_decode(token)
        const id = decode['user']['id']

        let response = await pauseSubscription(token,id,false,null)
        if (response.status === 204) {
            let token = getToken()
            response = await refreshToken(token)
            if (response.status === 200) {
                let data = await this.getTokenObject(response)
                token = data.token
                setToken(token)
                this.setState({ token: token })
                this.props.dispatch(handleSetToken(token))
                this.refreshPage()
            }
        } else {
            this.setState({ loading: false })
        }
    }

    resumeFromCancel = async (e) => {
        e.preventDefault();
        this.setState({ loading: true })
        const token = this.state.token
        const decode = jwt_decode(token)
        const id = decode['user']['id']

        let response = await cancelSubscription(token,id,false)
        if (response.status === 204) {
            let token = getToken()
            response = await refreshToken(token)
            if (response.status === 200) {
                let data = await this.getTokenObject(response)
                token = data.token
                setToken(token)
                this.setState({ token: token })
                this.props.dispatch(handleSetToken(token))
                this.refreshPage()
            }
        } else {
            this.setState({ loading: false })
        }
    }

    componentDidMount = async () => {
        this.setState({ loading: true })

        const token = getToken()
        this.setState({ token: token })

        const decode = jwt_decode(token)
        const userId = decode['user']['userId']

        const holdingUser = decode['user']['holding']
        this.setState({ holding: holdingUser })

        const activeUser = decode['user']['active']
        this.setState({ active: activeUser })

        const email = decode['user']['email']
        this.setState({email: email})
        
        let user
        let userInfo
        if (holdingUser) {
            user = await getHoldingUserInformation(token, userId)
            userInfo = await user.json()
            this.setState({ mtVersion: userInfo.mt_version })
            this.setState({ accountNumber: userInfo.account_number })
            let decodedPassword = Buffer.from(userInfo.account_password, "base64").toString();
            this.setState({ accountPassword: decodedPassword })
            this.setState({ brokerServer: userInfo.broker_server_id })
        } else {
            user = await getUserInformation(token, userId)
            userInfo = await user.json()
            this.setState({ mtVersion: userInfo.mt_version })
            this.setState({ accountNumber: userInfo.account_number })
            let decodedPassword = Buffer.from(userInfo.account_password, "base64").toString();
            this.setState({ accountPassword: decodedPassword })
            this.setState({ brokerServer: userInfo.broker_server_id })
        }

        if (activeUser){
            const customerId = decode['user']['customerId']
            const subscriptionId = decode['user']['subscriptionId']
            try {
                const customer = await getCustomer(token,customerId)
                const cus = await customer.json()
                const cardId = cus.cardId
                const card = await getCard(token,customerId,cardId)
                this.setState({ last4: card.last4})
                this.setState({ brand: card.brand})
            } catch (error) {
                this.setState({ last4: ''})
                this.setState({ brand: ''})
            }
    
            try {
                const subscription = await getSubscription(token, subscriptionId)
                const sub = await subscription.json()
                this.setState({ amount: sub.amount })
                this.setState({ currentPeriodEnd: timeStampToDate(sub.currentPeriodEnd) })
            } catch (error) {
                this.setState({ amount: '' })
                this.setState({ currentPeriodEnd: '' })
            }
        }

        const pause = decode['user']['pause']
        if (pause) {
            const pauseDate = decode['user']['pauseDate']
            const pauseDuration = decode['user']['pauseDuration']
            this.setState({ pause: pause })
            this.setState({ pauseDate: moment(pauseDate).format('MM/DD/YYYY') })
            const pauseEndDate = moment(this.state.pauseDate, 'MM/DD/YYYY').add(pauseDuration, 'weeks')
            this.setState({ pauseEndDate: moment(pauseEndDate._d).format('MM/DD/YYYY') })
        }
        
        const cancel = decode['user']['cancel']
        if (cancel) {
            this.setState({ cancel: cancel })
            const cancelDate = decode['user']['cancelDate']
            this.setState({ cancelDate: moment(cancelDate).format('MM/DD/YYYY') })
        }

        this.setState({ loading: false })
    }

    render() {
        return (
            <Container>
                <ContentWrapper>
                        {this.state.loading ? (
                            <LoadingContainer className='d-flex justify-content-center'>
                                <Spinner animation='border' style={{width: 100, height:100}}/>
                            </LoadingContainer>
                        ) : (
                            <AccountInfoContainer>
                                {this.state.active ? (
                                    <AccountWrapper>
                                        <ColumnContainer>
                                            <UpcomingCharges    
                                                pause={this.state.pause} 
                                                amount={this.state.amount}
                                                currentPeriodEnd={this.state.currentPeriodEnd} 
                                                pauseDate={this.state.pauseDate} 
                                                pauseEndDate={this.state.pauseEndDate}
                                                cancel={this.state.cancel}
                                                cancelDate={this.state.cancelDate}/>
                                            <PaymentInformation 
                                                brand={this.state.brand}
                                                last4={this.state.last4}
                                            />
                                        </ColumnContainer>
                                        <ColumnContainer>
                                            <Subscription 
                                                amount={this.state.amount}
                                            />
                                            {!this.state.cancel ? (
                                                <Pause 
                                                    pause={this.state.pause}
                                                    resumeFromPause={this.resumeFromPause}
                                                />
                                            ) : (<div></div>)}
                                            {!this.state.pause? (
                                                <Cancel 
                                                    cancel={this.state.cancel}
                                                    resumeFromCancel={this.resumeFromCancel}
                                                />
                                            ) : (<div></div>)}
                                        </ColumnContainer>
                                        <ColumnContainer>
                                            <AccountInformation
                                                mtVersion={this.state.mtVersion}
                                                accountNumber={this.state.accountNumber}
                                                accountPassword={this.state.accountPassword}
                                                brokerServer={this.state.brokerServer}
                                                email={this.state.email}
                                            />
                                        </ColumnContainer>
                                    </AccountWrapper>
                                ) : (
                                    <AccountWrapper>
                                        <ColumnContainer>
                                            <AccountInformation
                                                mtVersion={this.state.mtVersion}
                                                accountNumber={this.state.accountNumber}
                                                accountPassword={this.state.accountPassword}
                                                brokerServer={this.state.brokerServer}
                                                email={this.state.email}
                                            />
                                        </ColumnContainer>
                                    </AccountWrapper>
                                )}
                            </AccountInfoContainer>
                        )}
                </ContentWrapper>
            </Container>
        )
    }
}

export default withRouter(connect(privateMapStateToProps)(Account))