import { RECEIVE_PRIVATE_MODE } from '../actions/privateMode'

export default function privateMode(state = null, action) {
    switch (action.type) {
        case RECEIVE_PRIVATE_MODE:
            return action.privateMode

        default:
            return state
    }
}