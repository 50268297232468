import React, { Component } from 'react';
import { Container,ContentWrapper,HeaderWrapper,FormContainer,InlineForm,InputWrappers } from './style';
import { Button,Form,Alert } from 'react-bootstrap';
import { sendSupportEmail } from '@ftp/controllers';
import { privateMapStateToProps } from '../MapStateToProps';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { handleSetPrivateMode } from '../../actions/privateMode';
import { getToken } from '../../App';
import jwt_decode from 'jwt-decode';

class Support extends Component {
    constructor (props) {
        super()
        this.state = {
            token: '',
            name: '',
            email: '',
            subject: '',
            message: '',
            loading: false,
            status: 200,
            show: false
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        props.dispatch(handleSetPrivateMode(true))
    }

    handleSubjectChange = (e) => {
        this.setState({ subject: e.target.value || '' })
    }

    handleMessageChange = (e) => {
        this.setState({ message: e.target.value || ''})
    }

    setShow = (show) => {
        this.setState({ show: show })
    }

    componentDidMount = async () => {
        const token = getToken()
        const decode = jwt_decode(token)
        const name = decode['user']['username']
        const email = decode['user']['email']
        this.setState({ name: name })
        this.setState({ email: email })
    }

    handleSubmit = async (e) => {
        this.setState({ loading: true })
        e.preventDefault();
        
        const name = this.state.name
        const email = this.state.email
        const subject = this.state.subject
        const message = this.state.message

        const response = await sendSupportEmail(name,email,subject,message)
        if (response.status === 200) {
            this.setShow(true)
            this.setState({ subject: '' })
            this.setState({ message: '' })
        } else {
            this.setShow(true)
        }

        this.setState({ loading: false })
    }
    
    render() {
        return(
            <Container className='sign-in-container'>
                <ContentWrapper className='content-wrapper'>
                { this.state.show && this.state.status === 200 ? 
                    (<Alert variant='success' onClose={() => this.setShow(false)} dismissible> Support Email Sent! </Alert>) :
                    this.state.show && this.state.status === 200 ?
                    (<Alert variant='danger' onClose={() => this.setShow(false)} dismissible> Error Sending Support Email! </Alert>) :
                    (<Alert variant='danger' style={{visibility: 'hidden'}}> Hidden </Alert>) }
                    <FormContainer className='form-container'>
                        <HeaderWrapper>
                            <h2> Support </h2>
                        </HeaderWrapper>
                        <InlineForm onSubmit={this.handleSubmit}>
                            <InputWrappers>
                                <Form.Label>Subject*</Form.Label>
                                <Form.Control type={'text'} value={this.state.subject} placeholder={'Enter Subject'} onChange={e => this.handleSubjectChange(e)}/>
                            </InputWrappers>
                            <InputWrappers>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                  <Form.Label>Message*</Form.Label>
                                  <Form.Control as="textarea" value={this.state.message} rows={3} onChange={e => this.handleMessageChange(e)}/>
                                </Form.Group>
                            </InputWrappers>
                            <InputWrappers className='d-grid gap-2'>
                            <Button type={'submit'} variant='dark' disabled={
                                (this.state.name === '' || this.state.email === '' || this.state.subject === '' || this.state.message === '') ||
                                (this.state.loading)}> {this.state.loading ? 'Submitting...' : 'Submit'} </Button>
                            </InputWrappers>
                        </InlineForm>
                    </FormContainer>
                </ContentWrapper>
            </Container>
        )
    }
}

export default withRouter(connect(privateMapStateToProps)(Support))