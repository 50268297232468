import styled from "styled-components";

export const PerformanceContainer = styled.div`
    background-color: #f7f7f7;
    min-height: 100vh;
`;

export const ContentWrapper = styled.div`
    width: 100%;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
`;

export const AccountStatsWrapper = styled.div`
    display: table;
    width: 100%;
    max-width: 1440px;
    border-spacing: 1em 0.5em;
`;

export const AccountStatsContainer = styled.div`
    display:table-row;
    width: 100%;
    
`;

export const AccountAnalysisCard = styled.div`
    // border: 2px solid lightgrey;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    background-color: #ffff;
    display: table-cell;
`;