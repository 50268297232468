import React, { Component } from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import './style.css'

export const Container = styled.div`
    background-color: #f7f7f7;
    min-height: 100vh;
`;

export const ContentWrapper = styled.div`
    padding: 1em;
    display: table;
    margin: 5em auto;
`;

export const ContentCard = styled.div`
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    background-color: #ffff;
    margin: 3em 10em;
    min-width: 40vw;
`;

export const CardContent = styled.div`
    width: 100%;
    text-align: center;
    padding: 1em;
    font-weight: bold;
`;

export const Title = styled.div`
    width: 100%;
    text-align: center;
    font-size: 5rem;
`;

export const SubTitle = styled.div`
    text-align: center;
    padding: 1em;
`;

export const ButtonContainer = styled.div`
    margin: 1em
`;

class NotFound extends Component {
    render() {
        return(
            <Container>
                <ContentWrapper>
                    <ContentCard>
                        <CardContent>
                            <Title> 404 </Title>
                            <SubTitle>
                                <h6>We are sorry but the page you are looking for cannot be found.</h6>
                            </SubTitle>
                            <ButtonContainer>
                                <NavLink to='/' exact>
                                    <Button variant="link" size="lg" > Back To Home </Button>
                                </NavLink>
                            </ButtonContainer>
                        </CardContent>
                    </ContentCard>
                </ContentWrapper>
            </Container>
        )
    }
}

export default NotFound