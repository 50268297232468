import styled from 'styled-components';
import { size } from '../../styles/style';

export const Container = styled.div`
    background-color: #f7f7f7;
    @media screen and (min-width: ${size.laptop}) {
        height: 100vh;
        width: 100vw;
    }
`;

export const ContentWrapper = styled.div`
    padding: 1em;
    display: table;
    margin: 1em auto;
`;

export const ContentContainer = styled.div`
    background-color: #ffff;
    padding: 1em;
    height: 100%;
    width: 100%;
    margin: 1em 0em;
`;

export const FormContainer = styled.div`
    background-color: #ffff;
    padding: 1em;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
`;

export const HeaderWrapper = styled.div`
    text-align: center;
    margin: 0em 0em 3em 0em;
`;

export const CardContainer = styled.div`
    margin: 0em;
`;