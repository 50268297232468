import styled from 'styled-components';
import { size } from '../../styles/style';

export const Container = styled.div`
    background-color: #f7f7f7;
    @media screen and (max-width: ${size.laptop}) {
        height: 100vh;
        width: 100vw;
    }
    @media screen and (min-width: ${size.laptop}) {
        height: 100vh;
        width: 100vw;
    }
`;

export const ContentWrapper = styled.div`
    padding: 1em;
    display: table;
    margin: 1em auto;
`;

export const FormContainer = styled.div`
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    background-color: #ffff;
    padding: 1em;
    height: 100%;
    width: 100%;
`;

export const InlineForm = styled.form`
    display: inline;
`;

export const HeaderWrapper = styled.div`
    text-align: center;
    margin: 0em 0em 3em 0em;
`;

export const InputWrappers = styled.div`
    margin: 0.75em;
`;

export const FormInputs = styled.input`
    width: 100%;
    height: 2.5em;
    padding: 1em;
`;

export const LinkWrapper = styled.div`
    text-align: center;
    margin: 0.5em;

    & > a {
        color: black;
        text-decoration: none;
        margin: 0.5em;
    }

    & > a:hover {
        text-decoration: underline;
    }
`;

export const RadioDiv = styled.div`
    display: flex;
`;

export const Subheader = styled.h6`
    margin: 0.1em 1em;
`;

export const customStyles = {
    control: (provided, state) => ({
       ...provided,
       borderRadius: "2px",
       border: "1px solid #767676"
    })
};