import { RECEIVE_TOKEN } from '../actions/token'

export default function token(state = null, action) {
    switch (action.type) {
        case RECEIVE_TOKEN:
            return action.token

        default:
            return state
    }
}