import React, { Component } from 'react';
import { AccountCard } from './style';
import { Button,Row } from 'react-bootstrap';
import CancelSubscription from './Modals/CancelSubscription';

class Cancel extends Component {
    constructor () {
        super()
        this.state = {
            showCancelModal: false
        };
    }

    setShowCancelModal = (showCancelModal) => {
        this.setState({ showCancelModal })
    }

    handleCloseCancel = () => this.setShowCancelModal(false)
    handleShowCancel = () => this.setShowCancelModal(true)

    render() {
        return(
            <AccountCard>
                <Row>
                    <div className='account-content-two'>
                        <span className='account-header-two'>Cancel Your Subscription</span>
                        <div>
                            {!this.props.cancel ? (
                                <Button className='new-btn-link' variant='link' onClick={this.handleShowCancel}> Cancel </Button>
                            ) : (
                                <Button className='new-btn-link' variant='link' onClick={this.props.resumeFromCancel}> Resume </Button>
                            )}
                            
                        </div>
                    </div>
                </Row>
                <CancelSubscription showModal={this.state.showCancelModal} handleClose={this.handleCloseCancel}/>
            </AccountCard>
        )
    }
}

export default Cancel