import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { privateMapStateToProps } from '../MapStateToProps';

const FooterWrapper = styled.footer`
    border-top: 1px solid lightgrey;
    padding: 0.5em 1em;
`;

class Footer extends Component {
    render() {
        if (!this.props.privateMode) {
            return (
                <FooterWrapper>
                    © 2022 FlipThePips LLC.
                </FooterWrapper>
            )
        } else {
            return (
                <FooterWrapper>
                </FooterWrapper>
            )
        }
    }
}

export default withRouter(connect(privateMapStateToProps)(Footer))