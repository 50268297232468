import React, { /*useState, useEffect*/ } from "react";
import {
  VictoryLine,
  VictoryChart,
  VictoryAxis,
  VictoryVoronoiContainer,
  Point,
  VictoryTooltip,
} from "victory";
import styled from "styled-components";
import importedTheme from "./styles/theme";
import { timeFormat } from 'd3-time-format';

const ChartContainer = styled.div`
  height: 400px;
  font-size: 3rem;
  position: absoulte;
  overflow:hidden;
  display:flex
`;

const formatDate = timeFormat("%B %d %I:%M %p");
const getDate = (d) => new Date(d);
const formatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});

const VoronoiLabel = (props) => {
  const { /*datum,*/ x, y } = props;
  // const handleBalance = props.handleBalance
  const balance = {
    fill: importedTheme.color.white,
    paddingTop: 5,
    fontSize: 30,
    fontFamily: "Helvetica",
    textAnchor: "middle",
    fontWeight: "bold"
  };
  const date = {
    fill: importedTheme.color.darkGray,
    fontSize: 16,
    fontFamily: "Helvetica",
    textAnchor: "middle",
  }
  return (
    <g>
      <Point x={x} y={y} size={8} style={{ fill: "black", stroke: "white", strokeWidth: 2.5}} />
      {/* {useEffect(() => { handleBalance(datum.balance)})} */}
      <VictoryTooltip
        {...props}
        style={[
            balance,
            date
        ]}
        dy={-20}
        backgroundStyle={{ fill: importedTheme.color.white, position: "absolute"}}
        backgroundPadding={10}
        cornerRadius={5}
        pointerLength={5}
        flyoutStyle={{
            fill: importedTheme.color.black //"#15232d"
        }}
      />
    </g>
  );
};

const Chart = (props) => {
  // const [balance,handleBalance] = useState();
  // const [activePoint, handleActivatedPoint] = useState();
  // const [inactivePoint, handleDeactivatedPoint] = useState();

  // const updateBalance = props.updateBalance
  // const updateActivatedPoint = props.updateActivatedPoint
  // const updateDeactivatedPoint = props.updateDeactivatedPoint

  // useEffect(() => { updateBalance(balance)}, [balance,updateBalance])
  // useEffect(() => { updateActivatedPoint(activePoint) }, [activePoint,updateActivatedPoint])
  // useEffect(() => { updateDeactivatedPoint(inactivePoint) }, [inactivePoint,updateDeactivatedPoint])

  if (!props.data){
    return null;
  }

  const data = props.data

  return (
    <ChartContainer>
      <VictoryChart
      height={400}
      width={1900}
        style={{
          parent: {
            boxSizing: "border-box",
            display: "flex",
            justifyContent: "center",
            cursor: "crosshair", //(typeof activePoint === 'undefined' || activePoint === null) || activePoint.length > 0 ? "crosshair" : "auto",
            overflow: "hidden",
          },
        }}
        containerComponent={
          <VictoryVoronoiContainer
            className="line-chart-container"
            responsive={true}
            // onActivated={(points) => handleActivatedPoint(points)}
            // onDeactivated={(points) => handleDeactivatedPoint(points)}
            labels={({ datum }) => `${formatter.format(datum.balance)}\n ${formatDate(getDate(datum.date))}`}
            labelComponent={<VoronoiLabel 
                data={data}
                // handleBalance={handleBalance}
                // handleActivatedPoint={handleActivatedPoint}
                // handleDeactivatedPoint={handleDeactivatedPoint}
                />}
          />
        }
      >
    <VictoryAxis 
    name="axis"
    style={{ 
        axis: { display: "none" }, 
        ticks: { display: "none" },
        tickLabels: { display: "none" } 
        }} />
      <VictoryLine
        name="line"
        data={data}
        groupComponent={<svg />}
        y="balance"
        x="date"
        style={{
          data: { stroke: importedTheme.color.black, strokeWidth: 4 }
        }}
      />
      </VictoryChart>
    </ChartContainer>
  );
};

export default Chart;