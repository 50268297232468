import React, { Component } from 'react';

class Learn extends Component {
    componentDidMount() {

    }
    
    render() {
        return(
            <div>
            </div>
        )
    }
}

export default Learn