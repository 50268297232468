import { css } from 'styled-components';
import { usePaymentInputs, PaymentInputsWrapper } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import { Form,Button,Row,Col } from 'react-bootstrap';
import { CardContainer } from './style';
import './style.css'

const PaymentInputs = (props) => {  
  const {
        meta,
        wrapperProps,
        getCardImageProps,
        getCardNumberProps,
        getExpiryDateProps,
        getCVCProps,
        getZIPProps
    } = usePaymentInputs();
    
  const handleSubmit = props.handleSubmit

  if (!props.modal) {
    return (
        <Form className='d-grid gap-2' onSubmit={handleSubmit}>
            <CardContainer>
                <Row>
                    <Col>
                        <Form.Control className='name-inputs' type='text' placeholder="First Name" onChange={e => props.handleFirstNameChange(e.target.value)} />
                    </Col>
                    <Col>
                        <Form.Control className='name-inputs' type='text' placeholder="Last Name" onChange={e => props.handleLastNameChange(e.target.value)} />
                    </Col>
                </Row>
                <PaymentInputsWrapper
                  {...wrapperProps}
                  styles={{
                    fieldWrapper: {
                      base: css`
                        margin-bottom: 1rem;
                      `
                    },
                    inputWrapper: {
                      focused: css`
                        border-color: unset;
                        box-shadow: unset;
                        outline: 2px solid blue;
                        outline-offset: 2px;
                      `
                    },
                    input: {
                      cardNumber: css`
                        width: 15rem;
                      `,
                      expiryDate: css`
                        width: 10rem;
                      `,
                      cvc: css`
                        width: 5rem;
                      `
                    }
                  }}>
                    <svg {...getCardImageProps({ images })} />
                    <input {...getCardNumberProps({onChange: props.handleCardNumberChange,value: props.cardNumber})} />
                    <input {...getExpiryDateProps({onChange: props.handleExpiryDateChange,value: props.expiryDate})} />
                    <input {...getCVCProps({onChange: props.handleCvcNumberChange,value: props.cvc})}/>
                    <input {...getZIPProps({onChange: props.handleZipChange,value: props.zip})}/>
                </PaymentInputsWrapper>
          </CardContainer>
          <Button type={'submit'} variant='dark' disabled={
            (props.firstName === '' || props.lastName === '' || props.cardNumber === '' || props.expiryDate === '' || props.cvc === '') ||
            (props.loading) || (meta.error)}> {props.loading ? 'Submitting...' : 'Submit'} </Button>
      </Form>
    )
  } else {
    return (
        <Form className='d-grid gap-2' onSubmit={handleSubmit}>
            <CardContainer>
                <Row>
                    <Col>
                        <Form.Control className='name-inputs' type='text' placeholder="First Name" onChange={e => props.handleFirstNameChange(e.target.value)} />
                    </Col>
                    <Col>
                        <Form.Control className='name-inputs' type='text' placeholder="Last Name" onChange={e => props.handleLastNameChange(e.target.value)} />
                    </Col>
                </Row>
                <PaymentInputsWrapper
                  {...wrapperProps}
                  styles={{
                    fieldWrapper: {
                      base: css`
                        margin-bottom: 1rem;
                      `
                    },
                    inputWrapper: {
                      focused: css`
                        border-color: unset;
                        box-shadow: unset;
                        outline: 2px solid blue;
                        outline-offset: 2px;
                      `
                    },
                    input: {
                      cardNumber: css`
                        width: 15rem;
                      `,
                      expiryDate: css`
                        width: 10rem;
                      `,
                      cvc: css`
                        width: 5rem;
                      `
                    }
                  }}>
                    <svg {...getCardImageProps({ images })} />
                    <input {...getCardNumberProps({onChange: props.handleCardNumberChange,value: props.cardNumber})} />
                    <input {...getExpiryDateProps({onChange: props.handleExpiryDateChange,value: props.expiryDate})} />
                    <input {...getCVCProps({onChange: props.handleCvcNumberChange,value: props.cvc})}/>
                    <input {...getZIPProps({onChange: props.handleZipChange,value: props.zip})}/>
                </PaymentInputsWrapper>
          </CardContainer>
          <Button type={'submit'} variant='dark' disabled={
            (props.firstName === '' || props.lastName === '' || props.cardNumber === '' || props.expiryDate === '' || props.cvc === '') ||
            (props.loading) || (meta.error)}> {props.loading ? 'Saving...' : 'Save Changes'} </Button>

        <Button variant='outline-dark' onClick={props.close}> Cancel </Button>
      </Form>
    )
  }
}

export default PaymentInputs