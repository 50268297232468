import React, { Component } from 'react';
import { getPublicAccountBalance, getPublicAccountAnalysis } from "@ftp/controllers";
import ChartAndCards from '../Analysis/ChartAndCards';
import { publicCardData,publicCardTitles } from '../Analysis/AnalysisCards/CardDataStruct';

class Performance extends Component {
    constructor () {
        super()
        this.state = {
            data: [],
            period: 'month',
            cardTitles: publicCardTitles,
            cardData: [],
            loading: true,
            intervalId: ''
        };
    }

    publicTimePeriodSelector = async (period) => {
        if (period !== this.state.period){
            this.setState({loading: true})
            this.setState({ period })
            try {
                const data = await getPublicAccountBalance(period)
                this.setState({ data: data })
            } catch {
                this.setState({ data: [] })
            }
            
            this.setState({loading: false})
        }
    }

    refreshData = async () => {
        try {
            const cardTitles = this.state.cardTitles
            const analysis = await getPublicAccountAnalysis()
            const cardData = publicCardData(cardTitles, analysis[0])
            this.setState({ cardData: cardData })
        } catch {
            this.setState({ cardData: [] })
        }

        try {
            const data = await getPublicAccountBalance(this.state.period)
            this.setState({ data: data })
        } catch {
            this.setState({ data: [] })
        }
    }

    componentDidMount = async () => {
        this.refreshData()
        const intervalId =  setInterval(this.refreshData, 100000)
        this.setState({ intervalId: intervalId })
        this.setState({loading: false})
    }

    componentWillUnmount = () => {
        clearInterval(this.state.intervalId)
        this.setState = (state,callback)=>{
            return;
        };
    }
    
    render () {
        return(
            <ChartAndCards 
                data={this.state.data} 
                timePeriodSelector={this.publicTimePeriodSelector} 
                loading={this.state.loading}
                cardTitles={this.state.cardTitles}
                cardData={this.state.cardData}
                active={true}
            />
        )
    }
}

export default Performance;