import React, { Component } from 'react';
import { Container,ContentWrapper,HeaderWrapper,FormContainer,InlineForm,InputWrappers } from './style';
import { Button,Row,Form,Col,Alert } from 'react-bootstrap';
import { sendSupportEmail } from '@ftp/controllers';

class ContactUs extends Component {
    constructor (props) {
        super()
        this.state = {
            name: '',
            email: '',
            subject: '',
            message: '',
            loading: false,
            status: 200,
            show: false
        }

        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleNameChange = (e) => {
        this.setState({ name: e.target.value || '' })
    }

    handlEmailChange = (e) => {
        this.setState({ email: e.target.value || '' })
    }

    handleSubjectChange = (e) => {
        this.setState({ subject: e.target.value || '' })
    }

    handleMessageChange = (e) => {
        this.setState({ message: e.target.value || ''})
    }

    setShow = (show) => {
        this.setState({ show: show })
    }

    refreshPage = () => {
        window.location.reload(false);
    }

    handleSubmit = async (e) => {
        this.setState({ loading: true })
        e.preventDefault();
        const name = this.state.name
        const email = this.state.email
        const subject = this.state.subject
        const message = this.state.message

        const response = await sendSupportEmail(name,email,subject,message)
        if (response.status === 200) {
            this.setShow(true)
            this.setState({ name: '' })
            this.setState({ email: '' })
            this.setState({ subject: '' })
            this.setState({ message: '' })
        } else {
            this.setShow(true)
        }

        this.setState({ loading: false })
    }
    
    render() {
        return(
            <Container className='sign-in-container'>
                <ContentWrapper className='content-wrapper'>
                { this.state.show && this.state.status === 200 ? 
                    (<Alert variant='success' onClose={() => this.setShow(false)} dismissible> Support Email Sent! </Alert>) :
                    this.state.show && this.state.status === 200 ?
                    (<Alert variant='danger' onClose={() => this.setShow(false)} dismissible> Error Sending Support Email! </Alert>) :
                    (<Alert variant='danger' style={{visibility: 'hidden'}}> Hidden </Alert>) }
                    <FormContainer className='form-container'>
                        <HeaderWrapper>
                            <h2> Contact Us </h2>
                        </HeaderWrapper>
                        <InlineForm onSubmit={this.handleSubmit}>
                            <Row>
                                <Col>
                                    <InputWrappers>
                                        <Form.Label>Name*</Form.Label>
                                        <Form.Control type={'text'} value={this.state.name} placeholder={'Enter Name'} onChange={e => this.handleNameChange(e)}/>
                                    </InputWrappers>
                                </Col>
                                <Col>
                                    <InputWrappers>
                                        <Form.Label>Email*</Form.Label>
                                        <Form.Control type={'email'} value={this.state.email} placeholder={'Enter Email'} onChange={e => this.handlEmailChange(e)}/>
                                    </InputWrappers>
                                </Col>
                            </Row>
                            <InputWrappers>
                                <Form.Label>Subject*</Form.Label>
                                <Form.Control type={'text'} value={this.state.subject} placeholder={'Enter Subject'} onChange={e => this.handleSubjectChange(e)}/>
                            </InputWrappers>
                            <InputWrappers>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                  <Form.Label>Message*</Form.Label>
                                  <Form.Control as="textarea" value={this.state.message} rows={3} onChange={e => this.handleMessageChange(e)}/>
                                </Form.Group>
                            </InputWrappers>
                            <InputWrappers className='d-grid gap-2'>
                            <Button type={'submit'} variant='dark' disabled={
                                (this.state.name === '' || this.state.email === '' || this.state.subject === '' || this.state.message === '') ||
                                (this.state.loading)}> {this.state.loading ? 'Submitting...' : 'Submit'} </Button>
                            </InputWrappers>
                        </InlineForm>
                    </FormContainer>
                </ContentWrapper>
            </Container>
        )
    }
}

export default ContactUs