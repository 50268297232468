import { AccountCard,TextContainer } from './style';

const UpcomingCharges = (props) => {
    const currencyFormatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});
    return (
        <AccountCard>
        {props.pause ? 
        (<h1 className='account-header'>Subscription Paused</h1>) : 
        props.cancel ? 
        (<h1 className='account-header'>Subscription Canceled</h1>):
        (<h1 className='account-header'>Upcoming Charges</h1>)}
        {props.pause ? (
            <TextContainer>
                <span> Pause Start Date: {props.pauseDate} </span>
                <br />
                <span> Pause End Date: {props.pauseEndDate} </span>
            </TextContainer>
        ) : props.cancel ? (
            <TextContainer>
                <span> Cancel Date: {props.cancelDate} </span>
            </TextContainer>
        ) :
        (
        <TextContainer>
            <span> {props.currentPeriodEnd} </span>
            <TextContainer style={{margin: '0.5em 0em'}}>
                <h2 className='account-subheader'> {currencyFormatter.format(props.amount)} </h2>
            </TextContainer>
        </TextContainer>
        )}
    </AccountCard>
    )
}
export default UpcomingCharges