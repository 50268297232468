import React, { Component } from 'react';
import styled from 'styled-components';

export const Container = styled.div`
    background-color: #f7f7f7;
    min-height: 100vh;
`;

export const ContentWrapper = styled.div`
    padding: 1em;
    display: table;
    margin: 1em auto;
`;

export const ContentContainer = styled.div`
    margin 0em 25em
`;

export const ContentCard = styled.div`
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    background-color: #ffff;
    padding: 1em;
    margin: 1em;
    min-width: 40vw;
`;

export const Title = styled.div`
    width: 100%;
    text-align: center;
`;

class AboutUs extends Component {
    render() {
        return(
            <Container>
                <ContentWrapper>
                    <ContentContainer>
                        <ContentCard>
                            <Title>
                                <h1>Our Strategy</h1>
                            </Title>
                            <p>
                                We use a complex Algorithmic trading strategy to chart the market. 
                                This technique can generate profits at a more efficient and steady frequency then regular technical analysis. 
                                We focus in quality signals not quantity for our customers.
                            </p>
                            <p>
                                No trading experience is needed, FlipthePips provides a hands-off approach, set up your account, and let us handle the rest!
                            </p>
                            <p>
                                Getting alerts and signals can be a pain, so we do <strong>NOT</strong> send signals. We believe automating the entire trading process is more accurate, effective, 
                                and alleviates costly human error mistakes from occurring.
                            </p>
                            <p>
                                We analyze the market for you and execute our most accurate real-time prediction on which direction the market is profitable.
                            </p>
                            <p>
                                We consult and provide advice using our proprietary trading algorithmic  strategies to chart the market
                                You can make thousands of excuses or you can make thousands of dollars, <strong>YOU DECIDE!!</strong>
                            </p>
                        </ContentCard>
                        <ContentCard>
                            <Title>
                                <h1>Mission Statement</h1>
                            </Title>
                            <p>
                                We strive to make the Forex markets <strong>more profitable for all</strong> by providing our members with recommendations on the current Forex market in real-time. 
                                <strong> Our goal</strong> is to provide a <strong>hands-off approach</strong> by completely simplifying and executing the trading process on behalf of our members.
                                <strong> Our passion is trading</strong>, we want our members to have the opportunity to focus & fund their passion by facilitating a simple and ridiculously easy way to make
                                <strong> unlimited passive income</strong> on a daily basis.
                            </p>
                        </ContentCard>
                        <ContentCard>
                            <Title>
                                <h1>Frequently Asked Questions</h1>
                            </Title>
                             <p>
                                <strong>What is <em>FlipThePips</em>?</strong>
                                <br />
                                FlipThePips simply offers consulting and recommendations on the current Forex market in real-time using our proprietary algorithmic trading strategy.
                                <br />
                                <strong>We are not:</strong>
                                <br />
                                <ul>
                                    <li>Forex Brokers (A broker is required for all members and we recommend HugosWay)</li>
                                    <li>A Crypto Exchange</li>
                                </ul>
                            </p>
                            <p>
                                <strong>Do I have to place trades myself?</strong>
                                <br />
                                No, trades are executed according to the FlipThePips proprietary algorithm daily.
                            </p>
                            <p>
                                <strong>Do we teach Forex?</strong>
                                <br />
                                FlipThePips does not teach forex. We save you time and money by executing our most accurate real-time prediction on which direction the market is profitable.
                            </p>
                        </ContentCard>
                        <ContentCard>
                            <Title>
                                <h1>Disclaimer</h1>
                            </Title>
                            <p>
                                All kinds of trading carries a high risk to your capital and it is possible to incur losses that exceed your initial investment. 
                                Trading is not suitable for all investors. Before making any trading decision ensure that you fully understand all the risks involved and have read warnings on various sources. 
                                If you have any doubt about the risks involved with trading, seek advice from an independent financial advisor. 
                                We take no responsibility for any investment decisions made by the reader of our website or any recipient of our materials, software, services, emails and communications etc.
                                The information contained within any materials, software, services, emails and communications etc. provided by us is opinion only and does not constitute investment advice. 
                                They represent the author's personal views and trading experience and are subject to change without notice. 
                                By visiting our websites, or viewing our materials, software, services, emails and communications etc. you acknowledge that we are not providing financial advice and what you do with the information provided by us is your decision.
                                There is no guarantee that you will earn any money using the information, techniques, ideas or materials provided by our websites or other associated materials, software, services, emails and communications etc. 
                                No information contained within our websites, materials, software, services, emails and communications etc. constitutes any recommendation to buy, sell, order, or trade any product in the financial or spread betting markets.
                                The information contained in our websites, materials, software, emails, services and communications etc. is not necessarily real-time nor accurate, and analyses are our opinions only. 
                                It is important to know that past results are no guarantee of future performance. 
                                We have no liability to you or any third party for any loss or damage arising out of or in connection with, the disclosure of our websites, or our materials, software, services, emails and communications etc by us to you. 
                                We shall not be liable for any loss or damage arising out of the creation of our websites or our materials, software, services, emails and communications etc. 
                                All results and performance figures stated by us are estimates, hypothetical in nature and do not represent evidence of actual results achieved. 
                                Your use of our websites, materials, software, services and communications etc. and any dispute arising out of such use of them is subject to the law. 
                                We reserve the right to amend or replace our websites, materials, software, services, emails and communications etc. at any time and undertake no obligation to inform the user or to provide additional information.
                                All materials, software, services, emails and communications etc are for the sole use of the recipient. No recipient, may rely on them for any trading or non trading decisions. 
                                Neither the whole nor any part of our materials, software, services, emails and communications etc. may be copied, published, quoted, disclosed, distributed, circulated, reproduced, transmitted in any form or stored in or introduced into a retrieval system by any means or in any form, without the prior written consent of us.
                            </p>
                        </ContentCard>
                    </ContentContainer>
                </ContentWrapper>
            </Container>
        )
    }
}

export default AboutUs