import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../../styles/icons/ftpLogo.png';
import { size } from '../../styles/style';

const LogoWrapper = styled.div`
`;

const FtpLogo = styled.img`
    height: 4em;
    width: 4em;
    margin: 0.5em;

    @media screen and (max-width: ${size.tablet}) {
        height: 3em;
        width: 3em;
        margin: 0.5em;
    }
`;

const Logo = (props) => {
    let pathname
    if ((props.header === 'Billing' || props.header === 'Account') && (!props.active && (!props.subscriptionId || props.subscriptionId === ''))) {
        pathname = '/billing'
    } else {
        pathname = '/dashboard'
    }
    return (
        <LogoWrapper className='logo-wrapper'>
            {!props.private ? (
                <NavLink to='/' exact>
                    <FtpLogo className='logo' src={logo} alt="Home"/>
                    <span> FlipThePips™ </span>
                </NavLink>
            ) : (
                <NavLink to={pathname} exact>
                    <FtpLogo className='logo' src={logo} alt="Dashboard"/>
                </NavLink>
            ) }
        </LogoWrapper>
    )
}
export default Logo