import React, { Component } from 'react';
import jwt_decode from 'jwt-decode';
import { getToken, setToken } from '../../App';
import { getAccountAnalysis,getAccountBalance,getSubscription,getAccount,getOpenTrades,getClosedTrades,refreshToken } from '@ftp/controllers';
import ChartAndCards from '../Analysis/ChartAndCards';
import { privateCardData,privateCardTitles } from '../Analysis/AnalysisCards/CardDataStruct';
import { handleSetPrivateMode } from '../../actions/privateMode';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { privateMapStateToProps } from '../MapStateToProps';
import { handleSetToken } from '../../actions/token';
import { Redirect } from 'react-router-dom';
import moment from 'moment';

class Dashboard extends Component {
    constructor (props) {
        super()
        this.state = {
            token: '',
            userId: '',
            period: 'month',
            cardTitles: privateCardTitles,
            data: [],
            cardData: [],
            accountData: {},
            openTrades: [],
            closedTrades: [],
            isLoading: true,
            isHolding: true,
            isActive: true,
            intervalId: ''
        };

        props.dispatch(handleSetPrivateMode(true))
    }

    setToken = (token) => {
        this.setState({ token })
    }

    setUserId = (userId) => {
        this.setState({ userId })
    }

    setIsHolding = (isHolding) => {
        this.setState({ isHolding })
    }

    refreshPage = () => {
        window.location.reload(false);
    }

    timePeriodSelector = async (period) => {
        if (period !== this.state.period){
            this.setState({isLoading: true})
            this.setState({ period })
            
            try {
                const data = await getAccountBalance(this.state.userId, this.state.token, period)
                this.setState({ data: data })
            } catch {
                this.setState({ data: [] })
            }
            
            this.setState({isLoading: false})
        }

    }

    refreshData = async () => {
        const token = getToken()
        const decode = jwt_decode(token)
        const userId = decode['user']['id']        
        const exp = decode['exp']
        const now = moment().unix()
        // On data refresh, if token is expired redirect to sign in page
        if (now >= exp){
            return (<Redirect push to={{
                pathname: "/signin",
                state: { expired: true }
              }} />)
        } else {
            try {
                const accountData = await getAccount(token, userId)
                const accountRes = await accountData.json()
                this.setState({ accountData: accountRes })
            } catch {
                this.setState({ accountData: {} })
            }
            
            try {
                const cardTitles = this.state.cardTitles
                const analysis = await getAccountAnalysis(userId, token)
                const cardData = privateCardData(cardTitles, analysis[0])
                this.setState({ cardData: cardData })
            } catch {
                this.setState({ cardData: [] })
            }
    
            try {
                const data = await getAccountBalance(userId, token, this.state.period)
                this.setState({ data: data })
            } catch {
                this.setState({ data: [] })
            }
    
            this.setState({isLoading: false})
    
            try {
                const openTrades = await getOpenTrades(token, userId)
                this.setState({ openTrades: openTrades })
            } catch {
                this.setState({ openTrades: [] })
            }
    
            try {
                const closedTrades = await getClosedTrades(token, userId)
                this.setState({ closedTrades: closedTrades })
            } catch {
                this.setState({ closedTrades: [] })
            }
        }
    }

    refreshUserToken = async () => {
        const token = this.state.token
        const decode = jwt_decode(token)
        const holding = decode['user']['holding']
        const subsciptionId = decode['user']['subscriptionId']
        if (subsciptionId && holding) {
          const refreshedToken = await refreshToken(token)
          const response = await refreshedToken.json()
          setToken(response['token'])
          this.props.dispatch(handleSetToken(response['token']))
          this.setState({ token: token })
        } else if (subsciptionId && !holding) {
            this.refreshPage()
        }
    }

    componentDidMount = async () => {
        this.setState({isLoading: true})
        const token = getToken()
        this.setToken(token)

        const decode = jwt_decode(token)
        const userId = decode['user']['id']
        const holding = decode['user']['holding']
        const subsciptionId = decode['user']['subscriptionId']
        const active = decode['user']['active']

        this.setUserId(userId)
        this.setState({ isHolding: holding })
        if (holding) { // If account is in holding
            if (subsciptionId && !active) { // If account has an associated subscriptionId but is not active
                const response = await getSubscription(token,subsciptionId)
                const subscription = await response.json()
                if (subscription.status === 'active') { // If subscription is active
                    this.setState({ isActive: true })
                    // Refresh token until account status is updated and moved from holding
                    let intervalId = setInterval(this.refreshUserToken, 100000)
                    this.setState({ intervalId: intervalId })
                } else {
                    this.setState({ isActive: false })
                    // Display window that links to billing
                }
            } else if (!subsciptionId && !active) {
                this.setState({ isActive: false })
                // Display window that links to billing
            } else {
                this.setState({ isActive: true })
                // Refresh token until account status is updated and moved from holding
                let intervalId = setInterval(this.refreshUserToken, 100000)
                this.setState({ intervalId: intervalId })
            }
        } else {
            this.refreshData()
            let intervalId = setInterval(this.refreshData, 100000)
            this.setState({ intervalId: intervalId })
        }
        this.setState({isLoading: false})
    }

    componentDidUpdate = () => {
        const token = getToken()
        if(this.state.token !== token){
            this.setToken(token)
        }
    }

    componentWillUnmount = () => {
        clearInterval(this.state.intervalId)
        this.setState = (state,callback)=>{
            return;
        };
    }
    
    render () {
        return(
            <ChartAndCards 
                data={this.state.data} 
                timePeriodSelector={this.timePeriodSelector} 
                loading={this.state.isLoading}
                cardTitles={this.state.cardTitles}
                cardData={this.state.cardData}
                accountData={this.state.accountData}
                private={true}
                openTrades={this.state.openTrades}
                closedTrades={this.state.closedTrades}
                active={this.state.isActive}
                holding={this.state.isHolding}
            />
        )
    }
}

export default withRouter(connect(privateMapStateToProps)(Dashboard))