import styled from 'styled-components';

export const Container = styled.div`
    background-color: #f7f7f7;
    min-height: 100vh;
`;

export const LoadingContainer = styled.div`
    margin: 15em auto;
`;

export const ContentWrapper = styled.div`
    width: 100%;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
`;

export const AccountInfoContainer = styled.div`
`;

export const AccountWrapper = styled.div`
    margin: 1em;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
`;

export const ColumnContainer = styled.div`
`;

export const AccountCard = styled.div`
    // border: 2px solid lightgrey;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    background-color: #ffff;
    padding: 1em;
    margin: 1em;
`;

export const TextContainer = styled.div`
    color: rgb(39, 44, 53);
    margin: 1em 0em;
`;

export const PaymentMethod = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0.5em 0em;
`;

export const SubHeader = styled.span`
    font-weight: 500;
    font-stretch: normal;
    display: inline;
    font-size: 16px;
    letter-spacing: normal;
`;

export const FormContainer = styled.div`
    background-color: #ffff;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
`;

export const InputWrappers = styled.div`
`;

export const FormInputs = styled.input`
    width: 100%;
    height: 2.5em;
    padding: 1em;
`;

export const RadioDiv = styled.div`
    display: flex;
`;

export const customStyles = {
    control: (provided, state) => ({
       ...provided,
       borderRadius: "2px",
       border: "1px solid #767676"
    })
};