export const RECEIVE_USERNAME = 'RECEIVE_USERNAME'

export function receiveUsername(username) {
    if (username){
        return {
            type: RECEIVE_USERNAME,
            username
        }
    } else {
        return {
            type: RECEIVE_USERNAME,
            username: null
        }
    }
}

export function handleSetUsername(username) {
    return (dispatch) => {
        dispatch(receiveUsername(username))
    }
}