import React, { Component } from 'react';
import { Modal,Button,Form } from 'react-bootstrap';
import jwt_decode from 'jwt-decode';
import { handleSetToken } from '../../../actions/token';
import { refreshToken,pauseSubscription } from '@ftp/controllers';
import { getToken,setToken } from '../../../App';
import { privateMapStateToProps } from '../../MapStateToProps';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

class PauseSubscription extends Component {
    constructor () {
        super()
        this.state = {
            token: '',
            pauseDuration: 1,
            loading: false,
            showModal: false
        };
    }

    setPauseDuration = (pauseDuration) =>{
        this.setState({ pauseDuration })
    }

    getTokenObject = async (response) => {
        const data = await response.json()
        const tokenString = JSON.stringify(data)
        const token = JSON.parse(tokenString)

        return token
    }

    refreshPage() {
        window.location.reload(false);
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ loading: true })
        const token = this.state.token
        const decode = jwt_decode(token)
        const id = decode['user']['id']
        const pauseDuration = this.state.pauseDuration

        let response = await pauseSubscription(token,id,true,pauseDuration)
        if (response.status === 204) {
            let token = getToken()
            response = await refreshToken(token)
            if (response.status === 200) {
                let data = await this.getTokenObject(response)
                token = data.token
                setToken(token)
                this.props.dispatch(handleSetToken(token))
                this.setState({ loading: false })
                this.props.handleClose()
                this.refreshPage()
            }
        } else {
            this.setState({ loading: false })
        }
    }

    componentDidMount = async () => {
        const token = getToken()
        this.setState({ token: token })
    }

    render() {
        return (
            <Modal show={this.props.showModal} onHide={this.props.handleClose} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title> Pause Subscription </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Instead of canceling, schedule a pause. 
                    You can pause your subscription for up to 12 weeks beginning on your next billing date. 
                    While your subscription is paused, you won't be billed, and you won't copy trades. You can log in to resume or cancel any time.
                </Modal.Body>
                <Form onSubmit={this.handleSubmit}>
                    <Modal.Body>
                        <Form.Select defaultValue={this.state.pauseDuration} onChange={(e) => this.setPauseDuration(e.target.value)}>
                            <option value="1">1 Week</option>
                            <option value="2">2 Weeks</option>
                            <option value="3">3 Weeks</option>
                            <option value="4">4 Weeks</option>
                            <option value="5">5 Weeks</option>
                            <option value="6">6 Weeks</option>
                            <option value="7">7 Weeks</option>
                            <option value="8">8 Weeks</option>
                            <option value="9">9 Weeks</option>
                            <option value="10">10 Weeks</option>
                            <option value="11">11 Weeks</option>
                            <option value="12">12 Weeks</option>
                        </Form.Select>
                    </Modal.Body>
                    <Modal.Body>
                        <div className='d-grid gap-2'>
                            <Button variant='dark' type={'submit'}> Pause Subscription </Button>
                            <Button variant='outline-dark' onClick={this.props.handleClose}> Cancel </Button>
                        </div>
                    </Modal.Body>
                </Form>
            </Modal>
        )
    }
}

export default withRouter(connect(privateMapStateToProps)(PauseSubscription))