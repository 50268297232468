import React, { useState, useEffect } from 'react';
import AccountBalanceChart from './Chart/AccountBalanceChart';
import AccountStatus from './AccountStatus/AccountStatus';
import AnalysisCard from './AnalysisCards/AnalysisCard';
import Trades from './Trades/Trades';
import { PerformanceContainer,ContentWrapper,AccountStatsWrapper } from '../Dashboard/style';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Button, Alert } from 'react-bootstrap';

const ContentBox = styled.div`
    justify-content: center;
    margin: 1em 10em;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    // border: 2px solid lightgrey;
    background-color: #ffff;
    text-align: center;
`;

const ChartAndCards = (props) => {
    const [show, setShow] = useState(false);

    useEffect(() => { setShow(props.holding)}, [props.holding])

    if (props.active) {
        return (
            <PerformanceContainer>
                <ContentWrapper className="content-wrapper">
                {show ? (
                <Alert variant='secondary' onClose={() => setShow(false)} style={{margin: '1em'}} dismissible>
                    It can several minutes to hours to retrieve stats for accounts recently added to the system.
                </Alert>) : (<div></div>)}
                    <AccountBalanceChart data={props.data} timePeriodSelector={props.timePeriodSelector} isLoading={props.loading}/>
                </ContentWrapper>
                <ContentWrapper className="content-wrapper">
                    {props.private ? 
                        (<AccountStatus accountData={props.accountData}/>) : 
                        (<div />)}
                </ContentWrapper>
                <ContentWrapper className="content-wrapper">
                    <AccountStatsWrapper>
                        <AnalysisCard titles={props.cardTitles} cardData={props.cardData} />
                    </AccountStatsWrapper>
                </ContentWrapper>
                <ContentWrapper>
                {props.private ? 
                        (<Trades openTrades={props.openTrades} closedTrades={props.closedTrades}/>) :
                        (<div />)}
                </ContentWrapper>
            </PerformanceContainer>
        )
    } else {
        return (
            <PerformanceContainer>
                <ContentWrapper className="content-wrapper">
                    <ContentBox>
                        <h1>Incomplete Billing Information</h1>
                        <NavLink to='/billing' exact>
                            <Button variant='link' size="lg"> Go Back To Billing </Button>
                        </NavLink>
                    </ContentBox>
                </ContentWrapper>
            </PerformanceContainer>
        )
    }
}

export default ChartAndCards