import React, { Component } from 'react';
import { Modal,Button,Form } from 'react-bootstrap';
import { handleSetToken } from '../../../actions/token';
import { refreshToken,updateAccountInformtaion } from '@ftp/controllers';
import { getToken,setToken } from '../../../App';
import { privateMapStateToProps } from '../../MapStateToProps';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { InputWrappers } from '../style';
import Select from 'react-select';
import { getBrokers,getBrokerServers } from '@ftp/controllers';
import { getFilteredBrokers, getFilteredServers } from '@ftp/controllers';
import jwt_decode from 'jwt-decode';

class UpdateAccount extends Component {
    constructor () {
        super()
        this.state = {
            token: '',
            holding: false,
            mtVersion: 4,
            accountNumber: '',
            accountPassword: '',
            broker: '',
            brokerLabel: '',
            brokerServer: '',
            brokerServerLabel: '',
            brokers: [],
            filteredBrokers: [],
            brokerServers: [],
            filteredServers: [],
            loading: false
        };
    }

    setAccountNumber = (accountNumber) => {
        this.setState({ accountNumber })
    }

    setAccountPassword = (accountPassword) => {
        this.setState({ accountPassword })
    }

    setBroker = (broker) => {
        this.setState({ broker })
    }

    setBrokerLabel = (brokerLabel) => {
        this.setState({ brokerLabel })
    }

    setBrokerServer = (brokerServer) => {
        this.setState({ brokerServer })
    }

    setBrokerServerLabel = (brokerServerLabel) => {
        this.setState({ brokerServerLabel })
    }

    getTokenObject = async (response) => {
        const data = await response.json()
        const tokenString = JSON.stringify(data)
        const token = JSON.parse(tokenString)

        return token
    }

    refreshPage() {
        window.location.reload(false);
    }

    clearValue = (ref) => {
        ref.clearValue();
    };

    numbersOnly = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
           this.setAccountNumber(e.target.value)
        }
    }

    allFieldsFilled = () => {
        const fields = [this.state.username,this.state.email,this.state.password,
        this.state.accountNumber,this.state.accountPassword,this.state.broker,
        this.state.brokerServer]

        const result = fields.filter(item => item === '').length !== 0
        
        return result
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ loading: true })
        const token = this.state.token
        const decode = jwt_decode(token)
        const id = decode['user']['userId']
        const accountNumber = this.state.accountNumber
        const accountPassword = this.state.accountPassword
        const mtVersion = this.state.mtVersion
        const brokerServerId = this.state.brokerServer

        let response = await updateAccountInformtaion(token,id,accountNumber,accountPassword,mtVersion,brokerServerId)
        if (response.status === 200) {
            let token = getToken()
            response = await refreshToken(token)
            if (response.status === 200) {
                let data = await this.getTokenObject(response)
                token = data.token
                setToken(token)
                this.props.dispatch(handleSetToken(token))
                this.setState({ loading: false })
                this.props.handleClose()
                this.refreshPage()
            }
        } else {
            this.setState({ loading: false })
        }
    }

    componentDidMount = async () => {
        const token = getToken()
        this.setState({ token: token })

        const decode = jwt_decode(token)
        const holding = decode['user']['holding']
        this.setState({ holding: holding })

        const mtVersion = this.state.mtVersion
        const brokers = await getBrokers()
        this.setState({ brokers })

        const filteredBrokers = await getFilteredBrokers(brokers,mtVersion)
        this.setState({ filteredBrokers })

        const brokerServers = await getBrokerServers(null, mtVersion)
        this.setState({ brokerServers })

        const accountNumber = this.props.accountNumber
        this.setState({ accountNumber: accountNumber })

        const accountPassword = this.props.accountPassword
        this.setState({ accountPassword: accountPassword })

        const brokerServer = this.props.brokerServer
        this.setState({ brokerServer: brokerServer })

        const currentBrokerServer = await brokerServers.filter(x => x.id === brokerServer)[0]
        if (currentBrokerServer !== undefined) {
            this.setState({ brokerServerLabel: currentBrokerServer.name || '' })
            this.setState({ broker: currentBrokerServer.broker_id })
            const currentBroker = filteredBrokers.filter(x => x.value === currentBrokerServer.broker_id)[0]
            this.setState({ brokerLabel: currentBroker.label })
        }
    }

    componentDidUpdate = async (prevProps,prevState) => {
        if (prevState.mtVersion !== this.state.mtVersion) {
            try{
                this.clearValue(this.brokerRef)
                this.clearValue(this.serverRef)
                this.setBroker('')
                this.setBrokerServer('')
            } catch {}
            
            const mtVersion = this.state.mtVersion
            const brokers = this.state.brokers

            const filteredBrokers = getFilteredBrokers(brokers,mtVersion)
            this.setState({ filteredBrokers })

            const brokerServers = await getBrokerServers(null, mtVersion)
            this.setState({ brokerServers })
        }

        if (prevState.broker !== this.state.broker) {
            try{
                this.clearValue(this.serverRef)
            } catch {}
            
            const filteredServers = getFilteredServers(this.state.brokerServers,this.state.broker)
            this.setState({ filteredServers })
        }
    }

    componentWillUnmount = () => {
        this.setState = (state,callback)=>{
            return;
        };
    }

    render() {
        return (
            <Modal show={this.props.showModal} onHide={this.props.handleClose} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title> Update Account Information </Modal.Title>
                </Modal.Header>
                <Form onSubmit={this.handleSubmit}>
                    <Modal.Body>
                        <Form.Label>Account Number</Form.Label>
                        <Form.Control type='text' disabled={this.state.holding} autoComplete='off' value={this.state.accountNumber} onChange={this.numbersOnly} pattern="[0-9]*"/>
                        <br />
                        <Form.Label>Account Password</Form.Label>
                        <Form.Control type='password' disabled={this.state.holding} autoComplete='off' value={this.state.accountPassword} onChange={e => this.setAccountPassword(e.target.value)} />
                        <br />
                        <Form.Label>Select Broker</Form.Label>
                        <Select 
                            ref={ref => {
                                this.brokerRef = ref;
                            }}
                            value={{value: this.state.broker, label: this.state.brokerLabel}}
                            options={this.state.filteredBrokers}
                            placeholder="Select Broker"
                            isDisabled={this.state.holding}
                            onChange={(e) => {
                                try{
                                    this.setBroker(e.value)
                                    this.setBrokerLabel(e.label)
                                } catch {
                                    this.setBrokerServer('')
                                    this.setBrokerLabel('')
                                }
                                
                            }}
                        />
                        <br />
                        {this.state.broker !== '' ? (
                            <InputWrappers>
                                <Form.Label>Select Broker Server</Form.Label>
                                <Select
                                    ref={ref => {
                                      this.serverRef = ref;
                                    }}
                                    value={{value: this.state.brokerServer, label: this.state.brokerServerLabel}}
                                    options={this.state.filteredServers}
                                    placeholder="Select Broker Server"
                                    isDisabled={this.state.holding}
                                    onChange={(e) => {
                                        try{
                                            this.setBrokerServer(e.value)
                                            this.setBrokerServerLabel(e.label)
                                        } catch {
                                            this.setBrokerServer('')
                                            this.setBrokerServerLabel('')
                                        }
                                        
                                    }}
                                />
                            </InputWrappers>
                        ) : (
                        <div>
                        </div>)}
                    </Modal.Body>
                    <Modal.Body>
                        <InputWrappers className='d-grid gap-2'>
                            <Button 
                            type={'submit'} 
                            variant='dark'
                            disabled={this.allFieldsFilled() || this.state.loading || this.state.holding} >
                                {this.state.loading ? 'Updating Account Information...' : 'Update Account Information'}
                            </Button>
                            <Button variant='outline-dark' onClick={this.props.handleClose}> Cancel </Button>
                        </InputWrappers>
                    </Modal.Body>
                </Form>
            </Modal>
        )
    }
}

export default withRouter(connect(privateMapStateToProps)(UpdateAccount))