export const renameKey = (obj, oldKey, newKey) => {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
}

export const getFilteredBrokers = (allBrokers, mtVersion) => {
    const brokers = [...allBrokers]
    const filteredBroker = brokers.filter(broker => parseInt(mtVersion) === parseInt(broker.mt_version))
    const arr = JSON.parse(JSON.stringify(filteredBroker));
    arr.forEach( obj => renameKey( obj, 'id', 'value' ) );
    arr.forEach( obj => renameKey( obj, 'name', 'label' ) );

    return arr
}
export const getFilteredServers = (brokerServers,broker) => {
    const servers = [...brokerServers]
    const filterdServers = servers.filter(server => server.broker_id === broker)
    const arr = JSON.parse(JSON.stringify(filterdServers));
    arr.forEach( obj => renameKey( obj, 'id', 'value' ) );
    arr.forEach( obj => renameKey( obj, 'name', 'label' ) );

    return arr
}