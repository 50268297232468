import React from 'react';
import styled from "styled-components";
import { timeFormat } from 'd3-time-format';

const AxisContainer = styled.div`
    position: relative;
    width: 100%;
    height: 34px;
    border-top: 2px solid lightgrey;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    flex-direction: row;
    flex: 0 0 auto;
    -webkit-box-align: center;
    align-items: center;
    padding: 0px 24px;
    height: 34px;
`;

const TextElement = styled.span`
    opacity: 1;
`;

const HorizontalAxis = (props) => {
    let formatDate;
    let items = [];
    const data = props.data;
    const period = props.period;
    const getDate = (d) => new Date(d);
    if (data.length > 0) {
        if (period === 'day') {
            formatDate = timeFormat("%I:%M %p");
        } else {
            formatDate = timeFormat("%b %d");
        }

        items.push(formatDate(getDate(data[0].date)))
        for (let i = 1; i <= 3; i++){
            let x = Math.round(data.length*(0.25*i));
            try {
                items.push(formatDate(getDate(data[x].date)))
            } catch {
                items.push(formatDate(getDate(data[x])))
            }
        }
        items.push(formatDate(getDate(data[data.length-1].date)))
    }

    return (
        <AxisContainer>
            {items.map((item,index) =>
                <TextElement key={index}> {item} </TextElement>
            )}
        </AxisContainer>
    );
}

export default HorizontalAxis