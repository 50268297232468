import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { getBrokers,getBrokerServers, signUp } from '@ftp/controllers';
import { getFilteredBrokers, getFilteredServers } from '@ftp/controllers';
import { ContentWrapper, HeaderWrapper, FormContainer, InlineForm, InputWrappers, FormInputs, LinkWrapper, RadioDiv, Subheader, customStyles } from './style'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { handleSetStatusCode } from '../../actions/statusCode';
import { handleSetToken } from '../../actions/token';
import { privateMapStateToProps } from '../MapStateToProps';
import { Button, Alert } from 'react-bootstrap';
import { setToken } from '../../App';
import styled from 'styled-components';
import { size } from '../../styles/style';

const Container = styled.div`
    background-color: #f7f7f7;
    @media screen and (max-width: ${size.laptop}) {
        height: 100%;
        width: 100%;
    }
    @media screen and (min-width: ${size.laptopL}) {
        height: 100vh;
        width: 100vw;
    }
`;

class SignUp extends Component {
    constructor () {
        super()
        this.state = {
            username: '',
            email: '',
            password: '',
            mtVersion: 4,
            accountNumber: '',
            accountPassword: '',
            broker: '',
            brokerServer: '',
            brokers: [],
            filteredBrokers: [],
            brokerServers: [],
            filterdServers: [],
            show: false,
            loading: false
        };

        this.handleSubmit = this.handleSubmit.bind(this)
        this.numbersOnly = this.numbersOnly.bind(this)
    }

    setUsername = (username) => {
        this.setState({ username })
    }

    setEmail = (email) => {
        this.setState({ email })
    }

    setPassword = (password) => {
        this.setState({ password })
    }

    setMtVersion = (mtVersion) => {
        this.setState({ mtVersion })
    }

    setBroker = (broker) => {
        this.setState({ broker })
    }

    setBrokerServer = (brokerServer) => {
        this.setState({ brokerServer })
    }

    setAccountNumber = (accountNumber) => {
        this.setState({ accountNumber })
    }

    setAccountPassword = (accountPassword) => {
        this.setState({ accountPassword })
    }

    setShow = (show) => {
        this.setState({ show })
    }

    clearValue = (ref) => {
        ref.clearValue();
    };

    numbersOnly = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
           this.setAccountNumber(e.target.value)
        }
    }

    allFieldsFilled = () => {
        const fields = [this.state.username,this.state.email,this.state.password,
        this.state.accountNumber,this.state.accountPassword,this.state.broker,
        this.state.brokerServer]

        const result = fields.filter(item => item === '').length !== 0
        
        return result
    }

    getTokenObject = async (response) => {
        const data = await response.json()
        const tokenString = JSON.stringify(data)
        const token = JSON.parse(tokenString)

        return token
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ loading: true })
        const username = this.state.username
        const email = this.state.email
        const password = this.state.password
        const mtVersion = this.state.mtVersion
        const accountNumber = this.state.accountNumber
        const accountPassword = this.state.accountPassword
        const brokerServer = this.state.brokerServer

        const response = await signUp(username,email,password,mtVersion,accountNumber,accountPassword,brokerServer)
        this.setState({ loading: false })
        if (response.status === 201) {
            this.props.dispatch(handleSetStatusCode(response.status))

            const data = await this.getTokenObject(response)
            const token = data.token

            setToken(token)
            this.props.dispatch(handleSetToken(token))

            this.props.history.push({
                pathname: '/billing',
                state:
                {
                    statusCode: response.status
                }
            })
        } else {
            this.setShow(true)
        }
    }

    componentDidMount = async () => {
        const mtVersion = this.state.mtVersion
        const brokers = await getBrokers()
        this.setState({ brokers })

        const filteredBrokers = getFilteredBrokers(brokers,mtVersion)
        this.setState({ filteredBrokers })

        const brokerServers = await getBrokerServers(null, mtVersion)
        this.setState({ brokerServers })
    }

    componentDidUpdate = async (prevProps,prevState) => {
        if (prevState.mtVersion !== this.state.mtVersion) {
            try{
                this.clearValue(this.brokerRef)
                this.clearValue(this.serverRef)
                this.setBroker('')
                this.setBrokerServer('')
            } catch {}
            
            const mtVersion = this.state.mtVersion
            const brokers = this.state.brokers

            const filteredBrokers = getFilteredBrokers(brokers,mtVersion)
            this.setState({ filteredBrokers })

            const brokerServers = await getBrokerServers(null, mtVersion)
            this.setState({ brokerServers })
        }

        if (prevState.broker !== this.state.broker) {
            try{
                this.clearValue(this.serverRef)
            } catch {}
            
            const filterdServers = getFilteredServers(this.state.brokerServers,this.state.broker)
            this.setState({ filterdServers })
        }
    }

    render() {
        return(
            <Container className='sign-in-container'>
                <ContentWrapper>
                    { this.state.show ? 
                    (<Alert variant='danger' onClose={() => this.setShow(false)} dismissible>Username, Email, or Account Number already exists.</Alert>) :
                    (<Alert variant='danger' style={{visibility: 'hidden'}}> Hidden </Alert>) }
                </ContentWrapper>
                <ContentWrapper className='content-wrapper'>
                    <HeaderWrapper>
                        <h2> Create Your Account </h2>
                    </HeaderWrapper>
                    <FormContainer className='form-container'>
                        <InlineForm onSubmit={this.handleSubmit}>
                            <InputWrappers>
                                <FormInputs type={'text'} placeholder={'Username'} onChange={e => this.setUsername(e.target.value)} />
                            </InputWrappers>
                            <InputWrappers>
                                <FormInputs type={'email'} placeholder={'Email'} onChange={e => this.setEmail(e.target.value)} />
                            </InputWrappers>
                            <InputWrappers>
                                <FormInputs type={'password'} placeholder={'Password'} onChange={e => this.setPassword(e.target.value)}/>
                            </InputWrappers>
                            <RadioDiv key={'inline-radio'}>
                                <Subheader> Account Type </Subheader>
                                <Form.Check
                                    inline
                                    aria-selected
                                    defaultChecked
                                    label='MT4'
                                    name='group-1'
                                    type='radio'
                                    id='mt4'
                                    value={4}
                                    onChange={e => this.setMtVersion(e.target.value)}
                                />
                                <Form.Check
                                    inline
                                    label='MT5'
                                    name='group-1'
                                    type='radio'
                                    id='mt5'
                                    value={5}
                                    onChange={e => this.setMtVersion(e.target.value)}
                                />
                            </RadioDiv>
                            <InputWrappers>
                                <FormInputs 
                                type={'text'} 
                                placeholder={'Account Number'} 
                                pattern="[0-9]*"
                                value={this.state.accountNumber}
                                onChange={this.numbersOnly}
                                />
                            </InputWrappers>
                            <InputWrappers>
                                <FormInputs type={'password'} placeholder={'Account Password'} onChange={e => this.setAccountPassword(e.target.value)}/>
                            </InputWrappers>
                            <InputWrappers>
                                <Select 
                                    ref={ref => {
                                        this.brokerRef = ref;
                                    }}
                                    styles={customStyles}
                                    options={this.state.filteredBrokers}
                                    placeholder="Select Broker"
                                    onChange={(e) => {
                                        try{
                                            this.setBroker(e.value)
                                        } catch {
                                            this.setBrokerServer('')
                                        }
                                        
                                    }}
                                />
                            </InputWrappers>
                            {this.state.broker !== '' ? (
                                <InputWrappers>
                                    <Select
                                        ref={ref => {
                                          this.serverRef = ref;
                                        }}
                                        styles={customStyles}
                                        options={this.state.filterdServers}
                                        placeholder="Select Broker Server"
                                        onChange={(e) => {
                                            try{
                                                this.setBrokerServer(e.value)
                                            } catch {
                                                this.setBrokerServer('')
                                            }
                                            
                                        }}
                                    />
                                </InputWrappers>
                            ) : (
                            <div>

                            </div>)}
                            <InputWrappers className='d-grid gap-2'>
                                <Button 
                                type={'submit'} 
                                variant='dark'
                                disabled={this.allFieldsFilled() || this.state.loading} >
                                    {this.state.loading ? 'Creating Account...' : 'Create Account'}
                                </Button>
                            </InputWrappers>
                        </InlineForm>
                    </FormContainer>
                        <LinkWrapper className='link-wrapper'>
                        Already have a FlipThePips account?<NavLink to='/signin' exact>Sign In</NavLink>
                    </LinkWrapper>
                </ContentWrapper>
            </Container>
        )
    }
}

export default  withRouter(connect(privateMapStateToProps)(SignUp))