import React, { Component } from 'react';
import UpdatePayment from './Modals/UpdatePayment';
import { AccountCard,TextContainer,PaymentMethod,SubHeader } from './style';
import { Button } from 'react-bootstrap';

class PaymentInformation extends Component {
    constructor () {
        super()
        this.state = {
            showPaymentModal: false
        };
    }

    setShowPaymentModal = (showPaymentModal) => {
        this.setState({ showPaymentModal })
    }

    handleClosePayment = () => this.setShowPaymentModal(false)
    handleShowPayment = () => this.setShowPaymentModal(true)

    render() {
        return(
            <AccountCard>
                <h1 className='account-header'>Payment Information</h1>
                <TextContainer>
                    <PaymentMethod>
                        <div>
                            <SubHeader>Payment Method</SubHeader>
                        </div>
                        <div>
                            <Button className='new-btn-link' variant='link' onClick={this.handleShowPayment}>Update Payment</Button>
                        </div>
                    </PaymentMethod>
                    <h6> {this.props.brand}: •••• •••• •••• {this.props.last4} </h6>
                </TextContainer>
                <UpdatePayment showModal={this.state.showPaymentModal} handleClose={this.handleClosePayment}/>
            </AccountCard>
        )
    }
}

export default PaymentInformation