import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Button from 'react-bootstrap/Button'
import fallingSymbols from '../../styles/icons/allSymbolsFalling.png';
import { 
    ContentContainer, 
    ContentWrapper, 
    Section, 
    SectionColumnOne, 
    SectionColumnTwo, 
    SectionColumnTwoContentContainer, 
    TitleContainer, 
    BodyContainer, 
    ButtonContainer} from './HomePage';
import styled from 'styled-components';

export const Symbols = styled.img`
    width: 16em;
    height: 32em;
`;

class SectionThree extends Component {
    render() {
        return (
            <ContentContainer className='content'>
                <ContentWrapper className='content-wrapper'>
                    <Section className='section section-three'>
                        <SectionColumnOne className='section-column-one'>
                            <Symbols className='symbols' src={fallingSymbols} alt='' />
                        </SectionColumnOne>
                        <SectionColumnTwo className='section-column-two'>
                            <SectionColumnTwoContentContainer className='section-column-two-content-container' style={{margin:'0em'}}>
                                <TitleContainer className='title-container'>
                                    <h1> What Is Forex? </h1>
                                </TitleContainer>
                                <BodyContainer className='body-container'>
                                    <p>
                                    The Foreign Exchange (also known as Forex or FX) refers to the global, 
                                    over-the-counter market (OTC) where traders, investors,
                                    institutions and banks, buy and sell world currencies.
                                    The Forex Market is the largest financial market in the world, 
                                    with over $6.5 trillion in daily transactions.
                                    </p>
                                </BodyContainer>
                                {/* <ButtonContainer className='button-container'>
                                    <NavLink to='/learn' exact>
                                        <Button variant="dark" size="lg"> Learn More </Button>
                                    </NavLink>
                                </ButtonContainer> */}
                            </SectionColumnTwoContentContainer>
                        </SectionColumnTwo>
                    </Section>
                </ContentWrapper>
            </ContentContainer>
        );
    }
}

export default SectionThree