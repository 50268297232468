import React, { Component } from 'react';
import styled from 'styled-components';
import { size } from '../../styles/style';
import SectionOne from './SectionOne';
import SectionTwo from './SectionTwo';
import SectionThree from './SectionThree';

const HomeContainer = styled.div``;
export const ContentContainer = styled.div`
    background-color: #f7f7f7;
`;

export const ContentAltContainer = styled.div`
    background-color: #ffff;
`;

export const ContentWrapper = styled.div`
    width: 100%;
    max-width: 1440px;
    display: flex;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: ${size.tablet}) {
        width: 100%;
        max-width: 1440px;
        display: flex;
        margin-left: auto;
        margin-right: auto;
    }
`;

export const Section = styled.div`
    display: grid;
    max-width: 100%;
    grid-template-columns: 1fr 1fr;
    margin: 1em 5em;

    @media screen and (max-width: ${size.tablet}) {
        display: block;
        max-width: 100%;
        
        &.section-one {
            display: flex;
            max-width: 100%;
        }

        &.section-one > .section-column-one {
            display: none;
        }

        &.section-two > .section-column-one {
            margin: 0em;
        }

        &.section-three > .section-column-one {
            display: none;
        }
    }

    @media screen and (max-width: ${size.mobileL}) {
        margin: 1em 0.5em;
    }
`;

export const SectionColumnOne = styled.div`
    margin: 3em 1em 3em 6em;
    display: flex;
    justify-content: center;

    & .symbol {
        display: flex;
        justify-content: center;
    }
`;

export const SectionColumnTwo = styled.div`
    margin: 3em 6em 0em 1em;
    padding: auto;

    @media screen and (max-width: ${size.tablet}) {
        margin: 1em;
        padding: auto;
    }
`;

export const SectionColumnOneContentContainer = styled.div`
    &:nth-child(2) {
        margin: 0em;
        text-align: left;
    }

    @media screen and (max-width: ${size.tablet}) {
        margin: 0em;
    }
`;

export const SectionColumnTwoContentContainer = styled.div`
    margin: 4em 0em 0em 0em;    
`;

export const TitleContainer = styled.div`
    & > h1 {
        font-size: min(4vw,57.6px);
        font-weight: bold;
        margin: auto;
    }

    @media screen and (max-width: ${size.laptop}) {
        & > h1 {
            font-size: min(3vw,57.6px);
            font-weight: bold;
            margin: auto;
        }
    }

    @media screen and (max-width: ${size.tablet}) {
        & > h1 {
            text-align: center;
            font-size: min(6vw,50px);
            font-weight: bold;
            margin: auto;
        }
    }

    @media screen and (max-width: ${size.mobileL}) {
        & > h1 {
            text-align: center;
            font-size: min(8vw,50px);
            font-weight: bold;
            margin: auto;
        }
    }
`;

export const BodyContainer = styled.div`
    & > p {
        font-size: min(2vw,30px);
        margin: auto;
    }

    @media screen and (max-width: ${size.laptop}) {
        & > p {
            font-size: min(1.5vw,30px);
            margin: auto;
        }
    }

    @media screen and (max-width: ${size.tablet}) {
        & > p {
            text-align: center;
            font-size: min(3vw,30px);
            margin: auto;
        }
    }

    @media screen and (max-width: ${size.mobileL}) {
        & > p {
            text-align: center;
            font-size: min(4vw,20px);
            margin: auto;
        }
    }
`;

export const ButtonContainer = styled.div`
    margin: 1em 0em 0em 0em;
    display: flex;
    justify-content: center;

    & > a > button {
        margin: 0 auto;
    }

    @media screen and (max-width: ${size.tablet}) {
        & > a > button {
            padding: 0.5rem 1rem;
            font-size: 1rem;
        }
    }

    @media screen and (max-width: ${size.mobileL}) {
        & > a > button {
            padding: 0.5rem 1rem;
            font-size: 0.75rem;
        }
    }
`;

class HomePage extends Component {
    render() {
        return(
            <HomeContainer className='home'>
                <SectionOne />
                <SectionTwo />
                <SectionThree />
            </HomeContainer>
        )
    }
}

export default HomePage