import { combineReducers } from 'redux';
import token from './token';
import statusCode from './statusCode';
import username from './username';
import privateMode from './privateMode';

export default combineReducers({
    token,
    statusCode,
    username,
    privateMode
})