import { NavLink } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Links from './Links';
import Logo from './Logo';
import styled from 'styled-components';
import { size } from '../../styles/style';
import { Image, NavDropdown } from 'react-bootstrap';
import userAvatar from '../../styles/icons/userAvatar.png'
import { Link } from 'react-router-dom';
import { clearToken } from '../../App';

const NavWrapper = styled.div`
    display: flex;
    ${props => !props.private ? (
        `justify-content: space-between;`
    ) : (``)}
    max-width: 1600px;
    margin-right: auto;
    margin-left: auto;

    @media screen and (max-width: ${size.tablet}) {
        & { 
            display: none;
        }
    }
`;

export const NavLeft = styled.div`
  & > div > a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: black;
  }

  & > .logo-wrapper > a:hover {
    color: black;
  }

  & > .logo-wrapper > a > span {
    font-size: 1.5vw;
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  @media screen and (max-width: ${size.tablet}) {
    & > .logo-wrapper > a > span {
        font-size: 2.5vw;
        font-weight: 500;
        margin-top: 0px;
        margin-bottom: 0px;
      }
  }

  @media screen and (max-width: ${size.mobileL}) {
    & > .logo-wrapper > a > span {
      font-size: 4.5vw;
      font-weight: 500;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
`;

export const NavCenter = styled.div`

    @media screen and (max-width: ${size.laptop}) {
        & .nav-link-item a span {
          font-size: 1.75vw;
          font-weight: 500;
          margin-top: 0px;
          margin-bottom: 0px;
        }
    }

    @media screen and (min-width: 769px) {
        & {
            display: flex;
        }

        & .nav-link {
            display: flex;
            padding: 0em;
          }
          
    }
`;

export const NavRight = styled.div`
    & {
        display: flex;
    }
    ${props => props.private ? (`
        display: flex;
        float: right;
        margin-left: auto;
    `) : (``)}
    
    @media screen and (min-width: 2559px) {
        & .nav-right-item a button {
          padding: 0.5rem 1rem;
          font-size: 1.45rem;
        }
    }
`;

export const NavRightItem = styled.div`
    ${props => !props.private ? (`
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0.5em;
    `) : (`
        display: flex;
        justify-content: center;
        align-items: center;
    `)}
`;

const Navigation = (props) => {
    return (
        <NavWrapper className='navigation' private={props.private}>
            <NavLeft>
                <Logo 
                    private={props.private}
                    header={props.header}
                    active={props.active} 
                    subscriptionId={props.subscriptionId}/>
            </NavLeft>
            <NavCenter className='nav-center'>
                <Links private={props.private} header={props.header}/>
            </NavCenter>
            {!props.private ? (
                <NavRight className='nav-right'>
                    <NavRightItem className='nav-right-item sign-in'>
                        <NavLink to='/signin' exact>
                            <Button variant='dark' size="md"> Sign In </Button>
                        </NavLink>
                    </NavRightItem>
                    <NavRightItem className='nav-right-item sign-up'>
                        <NavLink to='/signup' exact>
                            <Button variant="dark" size="md"> Sign Up </Button>
                        </NavLink>
                    </NavRightItem>
                </NavRight>
            ) : (
                <NavRight className='nav-right' private={props.private}>
                    <NavRightItem className='nav-right-item' private={props.private}>
                        <Image roundedCircle
                            width={'45em'}
                            height={'45em'}
                            src={userAvatar}
                        />
                    </NavRightItem>
                    <NavRightItem className='nav-right-item' private={props.private}>
                        <NavDropdown
                            title={props.username}
                            id="nav-dropdown"
                            className='nav-dropdown'>
                            <NavDropdown.Item className='nav-dropdown-item' to='/account' as={Link}>Account</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item className='nav-dropdown-item' to='/support' as={Link}>Support</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item className='nav-dropdown-item' to='/signin' as={Link} onClick={clearToken}>Sign Out</NavDropdown.Item>
                        </NavDropdown>
                    </NavRightItem>
                </NavRight>
            ) }
        </NavWrapper>
    );
}

export default Navigation