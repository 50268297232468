import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import PaymentInputs from '../../Billing/PaymentInputs';
import { FormContainer } from '../style';
import jwt_decode from 'jwt-decode';
import { handleSetToken } from '../../../actions/token';
import { createCardToken,createNewCardFromToken,updateCustomerDefaultSource,refreshToken } from '@ftp/controllers';
import { getToken,setToken } from '../../../App';
import { privateMapStateToProps } from '../../MapStateToProps';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

class UpdatePayment extends Component {
    constructor () {
        super()
        this.state = {
            token: '',
            firstName: '',
            lastName: '',
            cardNumber: '',
            expiryDate: '',
            cvv: '',
            zip: '',
            price: '',
            show: false,
            loading: false,
            loadingScreen: false,
            showModal: false
        };

        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleFirstNameChange = (value) => {
        this.setState({ firstName: value || '' })
    }

    handleLastNameChange = (value) => {
        this.setState({ lastName: value || '' })
    }

    handleCardNumberChange = (e) => {
        this.setState({ cardNumber: e.target.value || '' })
    }

    handleExpiryDateChange = (e) => {
        this.setState({ expiryDate: e.target.value || ''})
    }

    handleCvvNumberChange = (e) => {
        this.setState({ cvv: e.target.value || ''})
    }

    handleZipChange = (e) => {
        this.setState({ zip: e.target.value || ''})
    }

    getTokenObject = async (response) => {
        const data = await response.json()
        const tokenString = JSON.stringify(data)
        const token = JSON.parse(tokenString)

        return token
    }

    refreshPage() {
        window.location.reload(false);
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ loading: true })
        const token = this.state.token
        const name = `${this.state.firstName} ${this.state.lastName}`
        const cardNumber = this.state.cardNumber
        const expiryDate = this.state.expiryDate
        const cvv = this.state.cvv
        const zip = this.state.zip
        const decode = jwt_decode(token)
        const customerId = decode['user']['customerId']

        let response = await createCardToken(token,name,cardNumber,expiryDate,cvv,zip)
        if (response.status === 201) {
            let data = await response.json()
            const cardToken = data['cardToken']
            response = await createNewCardFromToken(token, customerId, cardToken)
            data = await response.json()
            const defaultSource = data['id']

            if (response.status === 201) {
                response = await updateCustomerDefaultSource(token, customerId, defaultSource)

                if (response.status === 200) {
                    let token = getToken()
                    response = await refreshToken(token)

                    if (response.status === 200) {
                        data = await this.getTokenObject(response)
                        token = data.token
                        setToken(token)
                        this.props.dispatch(handleSetToken(token))
                        this.setState({ loading: false })
                        this.props.handleClose()
                        this.refreshPage()
                    } else {
                        this.setState({ loading: false })
                    }
                } else {
                    this.setState({ loading: false })
                }
            } else {
                this.setState({ loading: false })
            }
        } else {
            this.setState({ loading: false })
        }
    }

    componentDidMount = async () => {
        const token = getToken()
        this.setState({ token: token })
    }

    render() {
        return (
            <Modal show={this.props.showModal} onHide={this.props.handleClose} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title> Update Payment Information </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormContainer>
                    <PaymentInputs 
                        firstName={this.state.firstName}
                        handleFirstNameChange={this.handleFirstNameChange}
                        lastName={this.state.lastName}
                        handleLastNameChange={this.handleLastNameChange}
                        cardNumber={this.state.cardNumber}
                        handleCardNumberChange={this.handleCardNumberChange}
                        expiryDate={this.state.expiryDate}
                        handleExpiryDateChange={this.handleExpiryDateChange}
                        cvc={this.state.cvv}
                        handleCvcNumberChange={this.handleCvvNumberChange}
                        zip={this.state.zip}
                        handleZipChange={this.handleZipChange}
                        handleSubmit={this.handleSubmit}
                        loading={this.state.loading}
                        modal={true}
                        close={this.props.handleClose}
                    />
                </FormContainer>
            </Modal.Body>
        </Modal>
        )
    }
}

export default withRouter(connect(privateMapStateToProps)(UpdatePayment))