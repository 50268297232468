import { timeFormat } from 'd3-time-format';

const formatter = new Intl.NumberFormat('en-US');
const currencyFormatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});
const formatDate = timeFormat("%b %d");
const getDate = (d) => new Date(d);

export const publicCardTitles = ['Total Trades', 'Trades Won', 'Trades Lost', 'Total P/L', 'Profit', 'Loss',
                                    'Longs Won', 'Shorts Won','Best Trade', 'Worst Trade', 'Average Win', 'Average Loss']

export const publicCardData = (cardTitles,data) => {
    const analysisCard = [{
        title: cardTitles[0],
        item: formatter.format(data['total_trades'])
    },
    {
        title: cardTitles[1],
        item: `${formatter.format(100*(data['total_trades_won']/data['total_trades']).toFixed(3))}% - `,
        subitem: `${formatter.format(data['total_trades_won'])} of ${formatter.format(data['total_trades'])}`
    },
    {
        title: cardTitles[2],
        item: `${formatter.format(100*(data['total_trades_lost']/data['total_trades']).toFixed(3))}% - `,
        subitem: `${formatter.format(data['total_trades_lost'])} of ${formatter.format(data['total_trades'])}`
    },
    {
        title: cardTitles[3],
        item: currencyFormatter.format(data['total_profit'])
    },
    {
        title: cardTitles[4],
        item: currencyFormatter.format(data['profits'])
    },
    {
        title: cardTitles[5],
        item: currencyFormatter.format(data['losses'])
    },
    {
        title: cardTitles[6],
        item: `${formatter.format(100*(data['longs_won']/data['total_longs']).toFixed(3))}% - `,
        subitem: `${formatter.format(data['longs_won'])} of ${formatter.format(data['total_longs'])}`
    },
    {
        title: cardTitles[7],
        item: `${formatter.format(100*(data['shorts_won']/data['total_shorts']).toFixed(3))}% - `,
        subitem: `${formatter.format(data['shorts_won'])} of ${formatter.format(data['total_shorts'])}`
    },
    {
        title: cardTitles[8],
        item: `${currencyFormatter.format(data['best_trade'])} - `,
        subitem: formatDate(getDate(data['best_trade_date']))
    },
    {
        title: cardTitles[9],
        item: `${currencyFormatter.format(data['worst_trade'])} - `,
        subitem:  formatDate(getDate(data['worst_trade_date']))
    },
    {
        title: cardTitles[10],
        item: currencyFormatter.format(data['average_win'])
    },
    {
        title: cardTitles[11],
        item: currencyFormatter.format(data['average_loss'])
    }]

    return analysisCard
}

export const privateCardTitles = ['Balance','Client Name','Account Type','Leverage','Broker','Broker Server',
                                    'Total Trades', 'Trades Won', 'Trades Lost', 'Total P/L', 'Profit', 'Loss',
                                    'Longs Won', 'Shorts Won','Best Trade', 'Worst Trade', 'Average Win', 'Average Loss', 
                                    'Total Lots', 'Total Commission', 'Total Swap', 'Total Deposits', 'Total Withdrawals']

export const privateCardData = (cardTitles,data) => {
    const analysisCard = [{
        title: cardTitles[0],
        item: currencyFormatter.format(data['balance'])
    },
    {
        title: cardTitles[1],
        item: data['client_name']
    },
    {
        title: cardTitles[2],
        item: capitalizeFirstLetter(data['trade_mode'])
    },
    {
        title: cardTitles[3],
        item: parseInt(data['leverage'])
    },
    {
        title: cardTitles[4],
        item: data['broker']
    },
    {
        title: cardTitles[5],
        item: data['server']
    },
    {
        title: cardTitles[6],
        item: formatter.format(data['total_trades'])
    },
    {
        title: cardTitles[7],
        item: `${formatter.format(100*(data['total_trades_won']/data['total_trades']).toFixed(3))}% - `,
        subitem: `${formatter.format(data['total_trades_won'])} of ${formatter.format(data['total_trades'])}`
    },
    {
        title: cardTitles[8],
        item: `${formatter.format(100*(data['total_trades_lost']/data['total_trades']).toFixed(3))}% - `,
        subitem: `${formatter.format(data['total_trades_lost'])} of ${formatter.format(data['total_trades'])}`
    },
    {
        title: cardTitles[9],
        item: currencyFormatter.format(data['total_profit'])
    },
    {
        title: cardTitles[10],
        item: currencyFormatter.format(data['profits'])
    },
    {
        title: cardTitles[11],
        item: currencyFormatter.format(data['losses'])
    },
    {
        title: cardTitles[12],
        item: `${formatter.format(100*(data['longs_won']/data['total_longs']).toFixed(3))}% - `,
        subitem: `${formatter.format(data['longs_won'])} of ${formatter.format(data['total_longs'])}`
    },
    {
        title: cardTitles[13],
        item: `${formatter.format(100*(data['shorts_won']/data['total_shorts']).toFixed(3))}% - `,
        subitem: `${formatter.format(data['shorts_won'])} of ${formatter.format(data['total_shorts'])}`
    },
    {
        title: cardTitles[14],
        item: `${currencyFormatter.format(data['best_trade'])} - `,
        subitem: formatDate(getDate(data['best_trade_date']))
    },
    {
        title: cardTitles[15],
        item: `${currencyFormatter.format(data['worst_trade'])} - `,
        subitem:  formatDate(getDate(data['worst_trade_date']))
    },
    {
        title: cardTitles[16],
        item: currencyFormatter.format(data['average_win'])
    },
    {
        title: cardTitles[17],
        item: currencyFormatter.format(data['average_loss'])
    },
    {
        title: cardTitles[18],
        item: data['total_lots']
    },
    {
        title: cardTitles[19],
        item: currencyFormatter.format(data['total_commission'])
    },
    {
        title: cardTitles[20],
        item: currencyFormatter.format(data['total_swap'])
    },
    {
        title: cardTitles[21],
        item: currencyFormatter.format(data['total_deposits'])
    },
    {
        title: cardTitles[22],
        item: currencyFormatter.format(data['total_withdrawals'])
    }
]

    return analysisCard
}

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }