import React from 'react';
import styled from "styled-components";
import { AccountStatsContainer,AccountAnalysisCard } from '../../Dashboard/style';

const UnorderedList = styled.ul`
    width: 100%;
    list-style-type: none;
    padding: 1em;
`;

const ListItem = styled.li`
    width: 100%;
    border-bottom: 2px solid lightgrey;
`;

const TextItem = styled.span`
    float: right;
`;

const SubItem = styled.span`
    font-size: 12px;
`;

const AnalysisCard = (props) => {
    const titles = props.titles
    const cardData = props.cardData
    if (cardData !== 0 && cardData !== undefined) {
        let cardNum = 0
        return (
            <AccountStatsContainer className='account-stats-container'>
                {cardData.map((data,index) =>
                {if(index % 6 === 0) {
                    cardNum ++
                    return (
                        <AccountAnalysisCard className='account-analysis-card' key={`card-${cardNum}`}>
                            <UnorderedList key={`unordered-list-${cardNum}`}>
                                {cardData.map((data,i) =>
                                {if (i >= index && i <= index+5) {
                                    return (
                                        <ListItem key={data['title'].toLowerCase()}>
                                            {data['title']}: 
                                            <TextItem>
                                                {data['item']}
                                                {data['subitem'] ? (<SubItem>{data['subitem']}</SubItem>) : (<div></div>)}
                                            </TextItem>
                                        </ListItem>
                                    )
                                } else {
                                    return null
                                }}
                                )}
                            </UnorderedList>
                        </AccountAnalysisCard>
                    )} else {
                        return null
                    }}
                )}
            </AccountStatsContainer>
        )
    } else {
        let cardNum = 0
        return(
            <AccountStatsContainer className='account-stats-container'>
                {titles.map((title,index) =>
                {if(index % 6 === 0) {
                    cardNum ++
                    return (
                        <AccountAnalysisCard className='account-analysis-card' key={`card-${cardNum}`}>
                        <UnorderedList>
                            {titles.map((title,i) => 
                             {if (i >= index && i <= index+5) {
                                return (
                                <ListItem key={i} > {title}: </ListItem>
                                )} else {
                                    return null
                                }}
                            )}
                        </UnorderedList>
                        </AccountAnalysisCard>
                    )
                } else {
                    return null
                } }
            )}
            </AccountStatsContainer>
        )
    }
}
export default AnalysisCard