import { NavLink } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import { size } from '../../styles/style';

const LinkWrapper = styled.div`
    @media screen and (max-width: ${size.tablet}) {
        & {
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 4em;
            right: inherit;
            width: 200vw;
            background-color: #181818;
            color: #ffff;
        }

        & .nav-link-item {
          padding-left: 2%;
          margin-bottom: 0.5em;
        }
    
        & .nav-link-item a {
          text-decoration: none;
        }
    
        & .nav-link-item a span {
          color: #fff;
          font-size: min(5vw,57.6px);
          
        }
    
        & .nav-link-item a span:hover {
          color: #d3d3d3;
        }
    }
`;

const NavLinkItem = styled.div`
    @media screen and (min-width: 769px) {
        & {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 3px;
            margin-left: 1em;
            margin-right: 1em;
            padding-top: 1em;
            padding-bottom: 1em;
            border-bottom: 3px solid transparent;
        }

        &:hover {
            color: black;
            border-bottom: 3px solid black;
        }
          
        & > a {
          color: black;
          text-decoration: none;
          display: block;
          line-height: normal;
        }
        
        & > a:hover {
          color: black;
        }
        
        & > a > span {
          font-size: 1.25vw;
          font-weight: 500;
          margin-top: 0px;
          margin-bottom: 0px;
        }
    }

    @media screen and (max-width: 768px) {
        & > a > button {
            padding: 0.5rem 1rem;
            font-size: 1.15rem;
        }
    }
`;

const LinkHeader = styled.div`
    jusify-content: right;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3px;
    margin-left: 1em;
    margin-right: 1em;
`;

const Links = (props) => {
    if (!props.private) {
        return (
            <LinkWrapper className='nav-link'>
                <NavLinkItem className='nav-link-item' onClick={() => props.mobile && props.closeMobileMenu()} >
                    <NavLink to='/performance'>
                        <span>Performance</span>
                    </NavLink>
                </NavLinkItem>
                {/* <NavLinkItem className='nav-link-item' onClick={() => props.mobile && props.closeMobileMenu()}>
                    <NavLink to='/learn'>
                        <span>Learn</span>
                    </NavLink>
                </NavLinkItem> */}
                <NavLinkItem className='nav-link-item' onClick={() => props.mobile && props.closeMobileMenu()}>
                    <NavLink to='/contact-us'>
                        <span>Contact</span>
                    </NavLink>
                </NavLinkItem>
                <NavLinkItem className='nav-link-item' onClick={() => props.mobile && props.closeMobileMenu()}>
                    <NavLink to='/about-us'>
                        <span>About Us</span>
                    </NavLink>
                </NavLinkItem>
                <NavLinkItem className='nav-link-item' onClick={() => props.mobile && props.closeMobileMenu()}>
                    <NavLink to='/pricing'>
                        <span>Pricing</span> 
                    </NavLink>
                </NavLinkItem>
                {(props.mobile) &&
                    <NavLinkItem className='nav-link-item sign-in' onClick={() => props.mobile && props.closeMobileMenu()}>
                    <NavLink to='/signin' exact>
                        <Button variant='light' size="md"> Sign In </Button>
                    </NavLink>
                </NavLinkItem>
                }
            </LinkWrapper>
        )
    } else {
        return (
            <LinkWrapper className='nav-link'>
                <LinkHeader>
                    <h2>{props.header}</h2>
                </LinkHeader>
                {(props.mobile) &&
                    <NavLinkItem className='nav-link-item sign-in' onClick={() => props.mobile && props.closeMobileMenu()}>
                    <NavLink to='/signin' exact>
                        <Button variant='light' size="md"> Sign In </Button>
                    </NavLink>
                </NavLinkItem>
                }
            </LinkWrapper>
        )
    }
}

export default Links