import { AccountCard,TextContainer,SubHeader } from './style';
import { Row,Col } from 'react-bootstrap';

const Subscription = (props) => {
    const currencyFormatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});
    return (
        <AccountCard>
            <h1 className='account-header'>Your Subscription</h1>
            <TextContainer>
                <Row>
                    <Col>
                        <SubHeader> Plan </SubHeader>
                    </Col>
                    <Col>
                        <h6>{currencyFormatter.format(props.amount)}/mo</h6>
                    </Col>
                </Row>
            </TextContainer>
        </AccountCard>
    )
}

export default Subscription