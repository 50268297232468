export const RECEIVE_TOKEN = 'RECEIVE_TOKEN'

export function receiveToken(token) {
    
    if (token){
        return {
            type: RECEIVE_TOKEN,
            token
        }
    } else {
        const localToken = localStorage.getItem('token')
        if (localToken || localToken !== 'undefined'){
            return {
                type: RECEIVE_TOKEN,
                token: localToken
            }
        } else{ 
            return {
                type: RECEIVE_TOKEN,
                token: null
            }
        }
    }
}

export function handleSetToken(token) {
    return (dispatch) => {
        dispatch(receiveToken(token))
    }
}