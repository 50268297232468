import React, { Component } from 'react';
import { Modal,Button,Form } from 'react-bootstrap';
import { handleSetToken } from '../../../actions/token';
import { refreshToken,updateUserPassword } from '@ftp/controllers';
import { getToken,setToken } from '../../../App';
import { privateMapStateToProps } from '../../MapStateToProps';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

class ChangePassword extends Component {
    constructor () {
        super()
        this.state = {
            token: '',
            currentPassword: '',
            password: '',
            confirmedPassword: '',
            loading: false
        };
    }

    setCurrentPassword = (currentPassword) => {
        this.setState({ currentPassword })
    }

    setPassword = (password) => {
        this.setState({ password })
    }

    setConfirmedPassword = (confirmedPassword) => {
        this.setState({ confirmedPassword })
    }

    getTokenObject = async (response) => {
        const data = await response.json()
        const tokenString = JSON.stringify(data)
        const token = JSON.parse(tokenString)

        return token
    }

    refreshPage() {
        window.location.reload(false);
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ loading: true })
        const token = this.state.token
        const currentPassword = this.state.currentPassword
        const password = this.state.password
        const confirmedPassword = this.state.confirmedPassword

        let response = await updateUserPassword(token,currentPassword,password,confirmedPassword)
        if (response.status === 200) {
            let token = getToken()
            response = await refreshToken(token)
            if (response.status === 200) {
                let data = await this.getTokenObject(response)
                token = data.token
                setToken(token)
                this.props.dispatch(handleSetToken(token))
                this.setState({ loading: false })
                this.props.handleClose()
                this.refreshPage()
            }
        } else {
            this.setState({ loading: false })
        }
    }

    componentDidMount = async () => {
        const token = getToken()
        this.setState({ token: token })
    }

    render() {
        return (
            <Modal show={this.props.showModal} onHide={this.props.handleClose} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title> Change Email Address </Modal.Title>
                </Modal.Header>
                <Form onSubmit={this.handleSubmit}>
                    <Modal.Body>
                        <Form.Label>Current Password</Form.Label>
                        <Form.Control type='password' value={this.state.currentPassword} onChange={(e) => this.setCurrentPassword(e.target.value)}/>
                        <br />
                        <Form.Label>New Password</Form.Label>
                        <Form.Control type='password' value={this.state.password} onChange={(e) => this.setPassword(e.target.value)}/>
                        <br />
                        <Form.Label>Confirm New Password</Form.Label>
                        <Form.Control type='password' value={this.state.confirmedPassword} onChange={(e) => this.setConfirmedPassword(e.target.value)}/>
                    </Modal.Body>
                    <Modal.Body>
                        <div className='d-grid gap-2'>
                            <Button variant='dark' type={'submit'} 
                                disabled={(this.state.password !== this.state.confirmedPassword) || (this.state.currentPassword === '' || this.state.password === '' ||  this.state.confirmedPassword === '')}> 
                                {this.state.loading ? 'Saving...' : 'Save Changes'}
                            </Button>
                            <Button variant='outline-dark' onClick={this.props.handleClose}> Cancel </Button>
                        </div>
                    </Modal.Body>
                </Form>
            </Modal>
        )
    }
}

export default withRouter(connect(privateMapStateToProps)(ChangePassword))