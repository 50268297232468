import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import phoneScreenshot from '../../styles/icons/appScPlaceHolder.png';
import { 
    ContentContainer, 
    ContentWrapper, 
    Section, 
    SectionColumnOne, 
    SectionColumnTwo, 
    SectionColumnTwoContentContainer, 
    TitleContainer, 
    BodyContainer, 
    ButtonContainer } from './HomePage';
import styled from 'styled-components';
import { size } from '../../styles/style';

export const PhoneScreen = styled.img`
    width: 32em;
    height: 30em;
    margin-top: 1em;

    @media screen and (max-width: ${size.laptop}) {
        & {
            width: 22em;
            height: 20em;
            margin-top: 1em;
        }
    }
`;

class SectionOne extends Component {
    render() {
        return(
            <ContentContainer className='content'>
                <ContentWrapper className='content-wrapper'>
                    <Section className='section section-one'>
                        <SectionColumnOne className='section-column-one'>
                            <PhoneScreen className='phone-screen' src={phoneScreenshot} alt='' />
                        </SectionColumnOne>
                        <SectionColumnTwo className='section-column-two'>
                            <SectionColumnTwoContentContainer className='section-column-two-content-container'>
                                <TitleContainer className='title-container'>
                                    <h1> Create the Lifestyle You Want! </h1>
                                </TitleContainer>
                                <BodyContainer className='body-container'>
                                    <p>
                                    Forex signals are trading alerts sent directly to you. 
                                    No trading experience necessary. We analyze the market for you. 
                                    If you have a smartphone or computer, you can make passive income!
                                    </p>
                                </BodyContainer>
                                <ButtonContainer className='button-container'>
                                    <NavLink to='/signup' exact>
                                        <Button variant="dark" size="lg"> Sign Up </Button>
                                    </NavLink>
                                </ButtonContainer>
                            </SectionColumnTwoContentContainer>
                        </SectionColumnTwo>
                    </Section>
                </ContentWrapper>
            </ContentContainer>
        );
    }
}

export default SectionOne;