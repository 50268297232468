import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { signIn } from '@ftp/controllers';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { privateMapStateToProps } from '../MapStateToProps';
import { Container,ContentWrapper,HeaderWrapper,FormContainer,InlineForm,InputWrappers,FormInputs,LinkWrapper } from './style';
import { handleSetToken } from '../../actions/token';
import { handleSetStatusCode } from '../../actions/statusCode';
import { handleSetUsername } from '../../actions/username';
import { handleSetPrivateMode } from '../../actions/privateMode';
import { Button, Alert } from 'react-bootstrap';
import jwtDecode from 'jwt-decode';

class SignIn extends Component {
    constructor (props) {
        super()
        this.state = {
            token: '',
            username: '',
            password: '',
            statusCode: '',
            show: false,
            expired: false,
            isLoading: false
        };

        this.handleSubmit = this.handleSubmit.bind(this)
        props.dispatch(handleSetPrivateMode(false))
    }

    setUsername = (username) => {
        this.setState({ username })
    }

    setPassword = (password) => {
        this.setState({ password })
    }

    setToken = (token) => {
        this.setState({ token })
    }

    setStatusCode = (statusCode) => {
        this.setState({ statusCode })
    }

    setShow = (show) => {
        this.setState({ show })
    }
    
    setExpired = () => {
        try {
            const expired = this.props.location.state.expired
            this.setState({ expired })
        } catch {
            this.setState({ expired: false })
        }
    }

    setLoading = (isLoading) => {
        this.setState({ isLoading })
    }

    setUsernameOnLoad = () => {
        try {
            this.setUsername(this.props.location.state.username)
        } catch {
            this.setUsername('')
        }
    }

    setStatusCodeOnLoad = () => {
        try {
            this.setStatusCode(this.props.location.state.statusCode)
            this.setShow(true)
        } catch {
            this.setShow(false)
            this.setStatusCode('')
        }
    }

    showAlert = () => {
        try{
            if (this.props.location.state.expired) {
                this.setShow(true)
                this.props.history.replace(this.props.location.state.expired, null);
            } else {
                this.setStatusCode(this.props.location.state.statusCode)
                this.setShow(true)
                this.props.history.replace(this.props.location.state.statusCode, null);
            }
        } catch {
            this.setShow(false)
            this.setStatusCode('')
        }
    }

    getTokenObject = async(response) => {
        const data = await response.json()
        const tokenString = JSON.stringify(data)
        const token = JSON.parse(tokenString)

        return token
    }

    handleSubmit = async(e) => {
        e.preventDefault();
        this.setLoading(true)
        const username = this.state.username
        const password = this.state.password
        const response = await signIn(username,password)
        this.setStatusCode(response.status)
        
        let token
        if (this.state.statusCode === 200) {
            const data = await this.getTokenObject(response)
            token = data.token
            this.setToken(token);
        }

        this.props.dispatch(handleSetToken(token))
        this.props.dispatch(handleSetStatusCode(response.status))
        this.props.dispatch(handleSetUsername(username))
        localStorage.setItem('token', token)
        this.setLoading(false)
        
        // Decode the token, if the user has no subscription Id send them to billing
        if (token && token !== 'undefined') {
            const decode = jwtDecode(token)
            const subscriptionId = decode['user']['subscriptionId']
            if (!subscriptionId || subscriptionId === '') {
                this.props.history.push('/billing')
            } else {
                this.props.history.push('/dashboard')
            }
        } else {
            // Pushing user to history, if path was private else push to dashboard
            this.props.history.push('/dashboard')
        }
    }

    componentDidMount = () => {
        this.setStatusCode()        
        this.setUsernameOnLoad()
        this.setExpired()
        this.showAlert()
    }

    render() {
        return(
            <Container className='sign-in-container'>
                <ContentWrapper>
                    { this.state.statusCode === 201 && this.state.show ?
                    (<Alert variant='success' onClose={() => this.setShow(false)} dismissible>Account Created Successfully.</Alert>) :
                    this.state.expired && this.state.show ? 
                    (<Alert variant='danger' onClose={() => this.setShow(false)} dismissible>Session Expired. Please sign in.</Alert>) :
                    this.state.statusCode > 299 && this.state.show ? 
                    (<Alert variant='danger' onClose={() => this.setShow(false)} dismissible>Invalid username or password. Try clicking 'Forgot Password' if you're having trouble signing in.</Alert>) : 
                    (<Alert variant='danger' style={{visibility: 'hidden'}}> Hidden </Alert>) }
                </ContentWrapper>
                <ContentWrapper className='content-wrapper'>
                    <HeaderWrapper>
                        <h2> Sign in to FlipThePips </h2>
                    </HeaderWrapper>
                    <FormContainer className='form-container'>
                        <InlineForm onSubmit={this.handleSubmit}>
                            <InputWrappers>
                                <FormInputs type={'text'} placeholder={'Username'} value={this.state.username || ''} onChange={e => this.setUsername(e.target.value)} />
                            </InputWrappers>
                            <InputWrappers>
                                <FormInputs type={'password'} placeholder={'Password'} onChange={e => this.setPassword(e.target.value)} />
                            </InputWrappers>
                            <InputWrappers className='d-grid gap-2'>
                                <Button type={'submit'} 
                                        disabled={(this.state.username === '' || this.state.password === '') || this.state.isLoading}
                                        variant='dark'>
                                            {this.state.isLoading ? 'Signing In...' : 'Sign In'}
                                        </Button>
                            </InputWrappers>
                        </InlineForm>
                    </FormContainer>
                        <LinkWrapper className='link-wrapper'>
                            <NavLink to='/forgot' exact>Forgot Password?</NavLink>
                            &nbsp;
                            <NavLink to='/signup' exact>Don't Have An Account?</NavLink>
                    </LinkWrapper>
                </ContentWrapper>
            </Container>
        )
    }
}

export default withRouter(connect(privateMapStateToProps)(SignIn))