import { RECEIVE_STATUS_CODE } from '../actions/statusCode'

export default function statusCode(state = null, action) {
    switch (action.type) {
        case RECEIVE_STATUS_CODE:
            return action.statusCode

        default:
            return state
    }
}