import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { handleInitialData } from './actions/shared';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import NavBar from './components/NavBar/NavBar';
import Footer from './components/Footer/Footer';
import SignIn from './components/Authentication/SignIn';
import SignUp from './components/Authentication/SignUp';
import HomePage from './components/Home/HomePage';
import Learn from './components/Learn'
import AboutUs from './components/AboutUs';
import Pricing from './components/Pricing';
import ContactUs from './components/Support/ContactUs';
import Support from './components/Support/Support';
import Performance from './components/Dashboard/Performance';
import Dashboard from './components/Dashboard/Dashboard';
import Billing from './components/Billing/Billing';
import Forgot from './components/Authentication/Forgot';
import NotFound from './components/NotFound/NotFound';
import Settings from './components/Account/Account';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import PasswordReset from './components/Authentication/PasswordReset';

const Container = styled.div`
  display: grid;
  max-width: 100%;
  grid-template-columns: 1fr;
`;

class App extends Component {
  constructor () {
    super()
    this.state = {
        token: ''
    };
  }

  componentDidMount = () => {
    this.props.dispatch(handleInitialData())
    const token = getToken()
    this.setState({ token: token})
  }

  componentDidUpdate = (prevProps,prevState) => {
    const token = getToken()
    if (token) {
        if (token !== prevState.token) {
            this.setState({ token: token})
            setInterval(this.refreshUserData, 100000)
        }
    }
  }

  render(){
    return (
      <Router>
        <Fragment>
          <Container className='container-fluid p-0'>
            <NavBar />
            <Switch>
              <Route path='/' exact component={HomePage} />
              <Route path='/signup' exact component={SignUp} />
              <Route path='/signin' exact component={SignIn} />
              <Route path='/learn' exact component={Learn} />
              <Route path='/about-us' exact component={AboutUs} />
              <Route path='/pricing' exact component={Pricing} />
              <Route path='/contact-us' exact component={ContactUs} />
              <Route path='/performance' exact component={Performance} />
              <Route path='/forgot' exact component={Forgot} />
              <Route path='/password-reset' exact component={PasswordReset} />
              <PrivateRoute path='/dashboard' exact component={Dashboard} />
              <PrivateRoute path='/billing' exact component={Billing} />
              <PrivateRoute path='/account' exact component={Settings} />
              <PrivateRoute path='/support' exact component={Support} />
              <Route component={NotFound} />
            </Switch>
            <Footer />
            </Container>
        </Fragment>
      </Router>
    );
  }
}

export const setToken = (token) => {
  localStorage.setItem('token', token);
}

export const clearToken = () => {
    localStorage.removeItem('token');
}

export const getToken = () => {
  const tokenString = localStorage.getItem('token');

  return tokenString
};

const mapStateToProps = ({ token,statusCode,username,privateMode }) => {
  return {
    token,
    statusCode,
    username,
    privateMode
  }
}

const PrivateRoute = connect(mapStateToProps)(
  ({ component: Component, token, statusCode, username, privateMode, ...rest }) => (
    <Route
      {...rest}
      render={props => {
        const token = getToken()
        if (token === null || token === 'undefined') {
          return (<Redirect push to={{
                    pathname: "/signin",
                    state: { statusCode, expired: false, username }
                  }} />)
        } else {
          const decode = jwt_decode(token)
          const exp = decode['exp']
          const now = moment().unix()
          if (now >= exp){
            return (<Redirect push to={{
                pathname: "/signin",
                state: { statusCode, expired: true, username }
              }} />)
          } else {
            setToken(token)
            return (<Component {...props} />)
          }
        }
      }
    } />
  )
);

export default connect(mapStateToProps)(App)