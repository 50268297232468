import React from 'react';
import { OverlayTrigger,Tooltip } from 'react-bootstrap';
import styled from 'styled-components';

const currencyFormatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});

const AccountStatusContainer = styled.div`
    // border: 2px solid lightgrey;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    background-color: #ffff;
    display: grid;
    grid-template-columns: 1fr;
    margin: 0em 1em 0.5em 1em;
`;

const StyledTable = styled.table`
    text-align: center; 
    vertical-align: middle;
    margin: 0em 1em;
`;

const TableHead = styled.thead`
    border-bottom: 1px solid lightgrey;
`;

const Dot = styled.span`
    height: 1em;
    width: 1em;
    border-radius: 50%;
    display: inline-block;
`;

const AccountStatus = (props) =>{
    const accountStatus = props.accountData
    let bgColor = '#bfbfbf'
    if (Object.keys(accountStatus).length > 0) {
        if (accountStatus.status === 'connection_ok') {
            bgColor = '#33cc33'
        } else if (accountStatus.status === 'attempt_failed') {
            bgColor = '#ff0000'
        } else {
            bgColor = '#003399'
        }
    }

    return (
        <AccountStatusContainer>
            <StyledTable>
                <TableHead>
                    <tr>
                        <th>
                            Status
                        </th>
                        <th>
                            Name
                        </th>
                        <th>
                            Equity
                        </th>
                        <th>
                            Free Margin
                        </th>
                        <th>
                            Used Margin
                        </th>
                        <th>
                            Open Trades
                        </th>
                        <th>
                            Pending Orders
                        </th>
                        <th>
                            Daily Profit
                        </th>
                        <th>
                            Weekly Profit
                        </th>
                        <th>
                            Monthly Profit
                        </th>
                    </tr>
                </TableHead>
                <tbody>
                    <tr>
                        <td>
                        <OverlayTrigger
                            key='top'
                            placement='top'
                            overlay={
                              <Tooltip id={`tooltip-'top'`}> {accountStatus.status}</Tooltip>
                            }>
                                <Dot style={{backgroundColor: bgColor}}/>
                            </OverlayTrigger>
                            
                        </td>
                        <td>
                            {accountStatus['account_name']}
                        </td>
                        <td>
                            {currencyFormatter.format(accountStatus['equity']) || '$0.00'}
                        </td>
                        <td>
                            {currencyFormatter.format(accountStatus['free_margin']) || '$0.00'}
                        </td>
                        <td>
                            {currencyFormatter.format(accountStatus['used_margin']) || '$0.00'}
                        </td>
                        <td>
                            {parseInt(accountStatus['open_trades']) || 0}
                        </td>
                        <td>
                            {parseInt(accountStatus['pending_orders']) || 0}
                        </td>
                        <td>
                            {currencyFormatter.format(accountStatus['daily_profit']) || '$0.00'}
                        </td>
                        <td>
                            {currencyFormatter.format(accountStatus['weekly_profit']) || '$0.00'}
                        </td>
                        <td>
                            {currencyFormatter.format(accountStatus['monthly_profit']) || '$0.00'}
                        </td>
                    </tr>
                </tbody>
            </StyledTable>
        </AccountStatusContainer>
    )
}

export default AccountStatus;