import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import './style.css';

const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <div className="btn-group" role="group">
      {
        options.map((option) => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <button
              key={ option.text }
              type="button"
              onClick={ () => onSizePerPageChange(option.page) }
              className={ `btn ${isSelect ? 'btn-dark' : 'btn-outline-dark'}` }
            >
              { option.text }
            </button>
          );
        })
      }
    </div>
  );

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// const currencyFormatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});

const TradeTable = (props) => {
    const keys = ['id','ticket','type','symbol','lots','open_time','open_price','stop_loss','take_profit','close_time','close_price','commission','swap','profit']
    let trades = props.trades
    trades.forEach(trade => {
        for (const key in trade) {
            if (!keys.includes(key)){
                delete trade[key]
            }
            if (key === 'open_time' || key === 'close_time') {
                try{
                    trade[key] = trade[key].split('.')[0]
                } catch {
                    trade[key] = ''
                }
                
            }
            if (key === 'type') {
                trade[key] = capitalizeFirstLetter(trade[key])
            }
            if (key === 'profit') {
                trade[key] = parseFloat(trade[key])
            }
        }
    });

    const columns = [{
        dataField: 'id',
        text: 'ID',
        sort: true,
        
    }, {
        dataField: 'ticket',
        text: 'Ticket',
        sort: true
    }, {
        dataField: 'type',
        text: 'Type',
        sort: true
    }, {
        dataField: 'symbol',
        text: 'Symbol',
        sort: true
    }, {
        dataField: 'lots',
        text: 'Lots',
        sort: true
    }, {
        dataField: 'open_time',
        text: 'Opened',
        sort: true,
        style: {whiteSpace: 'nowrap'}
    }, {
        dataField: 'open_price',
        text: 'Price',
        sort: true
    }, {
        dataField: 'stop_loss',
        text: 'SL',
        sort: true
    }, {
        dataField: 'take_profit',
        text: 'TP',
        sort: true
    }, {
        dataField: 'close_time',
        text: 'Closed',
        sort: true,
        style: {whiteSpace: 'nowrap'}
    }, {
        dataField: 'close_price',
        text: 'Price',
        sort: true
    }, {
        dataField: 'commission',
        text: 'Commission',
        sort: true
    }, {
        dataField: 'swap',
        text: 'Swap',
        sort: true
    }, {
        dataField: 'profit',
        text: 'Profit',
        sort: true
    }]

    const defaultSorted = [{
        dataField: 'id',
        order: 'desc'
      }];

    const options = {
        sizePerPageRenderer,
        custom: true,
        totalSize: trades.length
    };

    return (
        <PaginationProvider pagination={ paginationFactory(options) }>
            {({
              paginationProps,
              paginationTableProps
            }) => (
                <div>
                  <BootstrapTable
                        hover
                        bootstrap4
                        bordered={false}
                        keyField="id"
                        data={ trades }
                        columns={ columns }
                        { ...paginationTableProps }
                        defaultSorted={ defaultSorted }
                  />
                  <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
                  <div >
                    <SizePerPageDropdownStandalone { ...paginationProps } />
                  </div>
                  
                  <div>
                    <PaginationListStandalone { ...paginationProps } />
                  </div>      
                  </div>            
                </div>
            )
        }
        </PaginationProvider>
    )
}

export default TradeTable