import React, { Component } from 'react';
import { Container,ContentWrapper,HeaderWrapper,ContentContainer,FormContainer } from './style';
import { Alert } from 'react-bootstrap';
import PaymentInputs from './PaymentInputs';
import jwt_decode from 'jwt-decode';
import { handleSetToken } from '../../actions/token';
import { privateMapStateToProps } from '../MapStateToProps';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getToken,setToken } from '../../App';
import { createCardToken,createSubscription,refreshToken,getPrice } from '@ftp/controllers';
import { Modal,Button,Spinner } from 'react-bootstrap';
import { handleSetPrivateMode } from '../../actions/privateMode';
import styled from 'styled-components';

const currencyFormatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});

const LoadingContainer = styled.div`
    margin: 15em auto;
`;

class Billing extends Component {
    constructor (props) {
        super()
        this.state = {
            token: '',
            firstName: '',
            lastName: '',
            cardNumber: '',
            expiryDate: '',
            cvv: '',
            zip: '',
            price: '',
            show: false,
            loading: false,
            loadingScreen: false,
            showModal: false
        };

        this.handleSubmit = this.handleSubmit.bind(this)
        props.dispatch(handleSetPrivateMode(true))
    }

    handleFirstNameChange = (value) => {
        this.setState({ firstName: value || '' })
    }

    handleLastNameChange = (value) => {
        this.setState({ lastName: value || '' })
    }

    handleCardNumberChange = (e) => {
        this.setState({ cardNumber: e.target.value || '' })
    }

    handleExpiryDateChange = (e) => {
        this.setState({ expiryDate: e.target.value || ''})
    }

    handleCvvNumberChange = (e) => {
        this.setState({ cvv: e.target.value || ''})
    }

    handleZipChange = (e) => {
        this.setState({ zip: e.target.value || ''})
    }

    setShow = (show) => {
        this.setState({ show })
    }

    setShowModal = (showModal) => {
        this.setState({ showModal })
    }

    handleClose = () => this.setShowModal(false)
    handleShow = () => this.setShowModal(true)

    getTokenObject = async (response) => {
        const data = await response.json()
        const tokenString = JSON.stringify(data)
        const token = JSON.parse(tokenString)

        return token
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ loading: true })
        const token = this.state.token
        const name = `${this.state.firstName} ${this.state.lastName}`
        const cardNumber = this.state.cardNumber
        const expiryDate = this.state.expiryDate
        const cvv = this.state.cvv
        const zip = this.state.zip
        const decode = jwt_decode(token)
        const customerId = decode['user']['customerId']

        let response = await createCardToken(token,name,cardNumber,expiryDate,cvv,zip)
        if (response.status === 201) {
            let data = await response.json()
            const paymentToken = data['cardToken']

            response = await createSubscription(token, customerId, paymentToken)
            if (response.status === 201) {
                let token = getToken()
                response = await refreshToken(token)
                if (response.status === 200) {
                    data = await this.getTokenObject(response)
                    token = data.token
                    setToken(token)
                    this.props.dispatch(handleSetToken(token))
                    this.setState({ loading: false })
                    this.props.history.push({
                        pathname: '/dashboard',
                        state:
                        {
                            statusCode: response.status
                        }
                    })
                } else {
                    this.setShow(true)
                    this.setState({ loading: false })
                }
            } else {
                this.setShow(true)
                this.setState({ loading: false })
            }
        } else {
            this.setShow(true)
            this.setState({ loading: false })
        }
    }

    componentDidMount = async () => {
        this.setState({ loadingScreen: true })
        const token = getToken()
        this.setState({ token: token })
        const decode = jwt_decode(token)
        const subscriptionId = decode['user']['subscriptionId']
        if (subscriptionId) {
            this.props.history.push('/dashboard');
        }
        const price = await getPrice('price_1JTdb9Fz8wc2671R2BnNU7Pr')
        this.setState({ price: currencyFormatter.format(price.amount) })
        this.setState({ loadingScreen: false })
    }
    
    render() {
        return (
            <Container className='sign-in-container'>
                {this.state.loadingScreen ? (
                    <LoadingContainer className='d-flex justify-content-center'>
                        <Spinner animation='border' style={{width: 100, height:100}}/>
                    </LoadingContainer>
                ) : (
                    <div>
                        <ContentWrapper>
                            { this.state.show ? 
                            (<Alert variant='danger' onClose={() => this.setShow(false)} dismissible>Error Processing Billing Information!</Alert>) :
                            (<Alert variant='danger' style={{visibility: 'hidden'}}> Hidden </Alert>) }
                        </ContentWrapper>
                        <ContentWrapper className='content-wrapper'>
                            <HeaderWrapper>
                                <h2> Add Your Billing Info </h2>
                                <p> Review your subsciption and add a payment method to your account. </p>
                            </HeaderWrapper>
                            <ContentContainer>
                                <div style={{padding:10}}>
                                    <h4>Your Subscription</h4>
                                </div>
                                <div style={{padding:10}}>
                                    <h6 style={{display: 'inline-block',width: '65%'}}> Standard Trading Membership </h6>
                                    <h6 style={{display: 'inline-block',width: '35%',textAlign: 'right'}}> {this.state.price}/mo </h6>
                                </div>
                                <div> 
                                <Button variant="link" onClick={this.handleShow}> Review Plan </Button>
                                </div>
                                <Modal show={this.state.showModal} onHide={this.handleClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title> {this.state.price} Per Month </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body> Please note: We recommend an initial investment of $1000 and the monthly membership fee is non-negotiable. </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={this.handleClose}>
                                          Close
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </ContentContainer>
                            <ContentContainer>
                                <div style={{padding: 10}}>
                                    <h4 style={{display: 'inline-block',width: '65%'}}>Total Due Today</h4>
                                    <h4 style={{display: 'inline-block',width: '35%',textAlign: 'right'}}>{this.state.price}</h4>
                                </div>
                            </ContentContainer>
                            <FormContainer autoComplete='off'>
                                <PaymentInputs
                                    firstName={this.state.firstName}
                                    handleFirstNameChange={this.handleFirstNameChange}
                                    lastName={this.state.lastName}
                                    handleLastNameChange={this.handleLastNameChange}
                                    cardNumber={this.state.cardNumber}
                                    handleCardNumberChange={this.handleCardNumberChange}
                                    expiryDate={this.state.expiryDate}
                                    handleExpiryDateChange={this.handleExpiryDateChange}
                                    cvc={this.state.cvv}
                                    handleCvcNumberChange={this.handleCvvNumberChange}
                                    zip={this.state.zip}
                                    handleZipChange={this.handleZipChange}
                                    handleSubmit={this.handleSubmit}
                                    loading={this.state.loading}/>
                            </FormContainer>
                        </ContentWrapper>
                    </div>
                )}
            </Container>
        )
    }
}

export default withRouter(connect(privateMapStateToProps)(Billing))