import React, { Component } from 'react';
import Navigation from './Navigation';
import './NavBar.css'
import MobileNavigation from './MobileNavigation';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { privateMapStateToProps } from '../MapStateToProps';
import jwtDecode from 'jwt-decode';

const Header = styled.header`
    width: 100%;
    // border-bottom: 1px solid lightgrey;
    box-shadow: 0 .1rem .15rem rgba(0,0,0,.075);
    position: fixed;
    top: 0;
    background-color: #ffff;
    z-index: 5;
`;

class NavBar extends Component {
    constructor (props) {
        super()
        this.state = {
            token: '',
            username: '',
            header: '',
            holding: false,
            active: false,
            subscriptionId: ''
        };
    }

    capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }

    componentDidMount = () => {
        const pathname = this.props.location.pathname
        const header = this.capitalizeFirstLetter(pathname.replace(/[^a-z0-9 -]/gi, ''))
        this.setState({ header: header })

        if (this.props.token && this.props.token !== 'undefined') {
            const decode = jwtDecode(this.props.token)
            this.setState({ username: decode.user.username || 'Username' })

            const holding = decode['user']['holding']
            const active = decode['user']['active']
            const subscriptionId = decode['user']['subscriptionId']

            this.setState({ holding: holding })
            this.setState({ active: active })
            this.setState({ subscriptionId: subscriptionId })
        }
    }

    componentDidUpdate = (prevProps,props) => {
        const pathname = this.props.location.pathname
        const header = this.capitalizeFirstLetter(pathname.replace(/[^a-z0-9 -]/gi, ''))
        
        if (props.header !== header) {
            this.setState({ header: header })
        }

        if ((this.props.token && this.props.token !== 'undefined') && prevProps.token !== this.props.token) {
            const decode = jwtDecode(this.props.token)
            this.setState({ username: decode.user.username || 'Username' })

            const holding = decode['user']['holding']
            if (props.holding !== holding) {
                this.setState({ holding: holding })
            }

            const active = decode['user']['active']
            if (props.active !== active) {
                this.setState({ active: active })
            }

            const subscriptionId = decode['user']['subscriptionId']
            if (props.subscriptionId !== subscriptionId) {
                this.setState({ subscriptionId: subscriptionId })
            }
        }
    }

    render() {
        return (
            <Header className='affixed'>
                <Navigation 
                    private={this.props.privateMode} 
                    username={this.state.username} 
                    header={this.state.header}
                    holding={this.state.holding}
                    active={this.state.active}
                    subscriptionId={this.state.subscriptionId}/>
                <MobileNavigation 
                    private={this.props.privateMode} 
                    username={this.state.username} 
                    header={this.state.header}
                    holding={this.state.holding}
                    active={this.state.active}
                    subscriptionId={this.state.subscriptionId}/>
            </Header>
        )
    }
}

export default withRouter(connect(privateMapStateToProps)(NavBar))