import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import styled from 'styled-components';
import TradeTable from './TradeTable';
import './style.css'

const TradesContainer = styled.div`
    // border: 2px solid lightgrey;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    background-color: #ffff;
    display: grid;
    grid-template-columns: 1fr;
    margin: 0.5em 1em 0.5em 1em;
`;

const TabWrapper = styled.div`
    padding: 1em 1em;
`;

const Trades = (props) => {
    const [key, setKey] = useState('open-trades');
    return (
        <TradesContainer>
            <TabWrapper>
                <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3 tabs">
                    <Tab eventKey={'open-trades'} title="Open Trades">
                        <TradeTable tradeType={'open-trades'} trades={props.openTrades} />
                    </Tab>
                    <Tab eventKey={'closed-trades'} title="Closed Trades">
                        <TradeTable tradeType={'closed-trades'} trades={props.closedTrades} />
                    </Tab>
                </Tabs>
            </TabWrapper>
        </TradesContainer>
    )
}

export default Trades