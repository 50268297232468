import React, { useState, useEffect, useRef } from 'react';
import Balance from './Balance';
import Chart from './Chart';
import PeriodSelector from './PeriodSelector';
import HorizontalAxis from './HorizontalAxis';
import styled from 'styled-components';
import * as ReactBootStrap from 'react-bootstrap';

const ChartWrapper = styled.div`
    margin: 1em;
    //border: 2px solid lightgrey;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    background-color: #ffff;
    z-index: 1;
`;

const ControlBarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    flex-direction: row;
    padding: 24px 32px 16px;
    min-height: 135px;
    
`;

const usePrevious = (value) => {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    },[value])
    return ref.current
}

const ChartContainer = (props) => {
    let usedLoading
    if (props.prevPeriod !== props.period) {
        usedLoading = props.loading
    } else {
        usedLoading = props.isLoading
    }

    return(
        <div>
        {usedLoading ? 
        (<div 
            style={{height: 400, padding: 150}} 
            className='d-flex justify-content-center'>
            {<ReactBootStrap.Spinner animation='border' style={{width: 100, height:100}}/>}
        </div>) :
        <Chart 
            data={props.data} 
            updateBalance={props.updateBalance}
            updateActivatedPoint={props.updateActivatedPoint} 
            updateDeactivatedPoint={props.updateDeactivatedPoint} 
        />
        }
        </div>
    )
}

const AccountBalanceChart = (props) => {
    const timePeriodSelector = props.timePeriodSelector
    const data = props.data
    const isLoading = props.isLoading
    const [balance,updateBalance] = useState();
    const [activePoint,updateActivatedPoint] = useState();
    const [inactivePoint,updateDeactivatedPoint] = useState();
    const [period,updatePeriod] = useState('month');
    const [loading,updateLoading] = useState(true);
    const prevPeriod = usePrevious(period)

    useEffect(() => { timePeriodSelector(period) }, [period,timePeriodSelector])
    return (
        <ChartWrapper className="chart-wrapper">
            <ControlBarContainer className="control-bar-container">
                <Balance data={data} balance={balance} activePoint={activePoint} inactivePoint={inactivePoint}/>
                <PeriodSelector period={period} updatePeriod={updatePeriod} updateLoading={updateLoading}/>
            </ControlBarContainer>
            <ChartContainer 
                prevPeriod={prevPeriod} 
                period={period} 
                loading={loading} 
                isLoading={isLoading}
                data={data} 
                updateBalance={updateBalance}
                updateActivatedPoint={updateActivatedPoint} 
                updateDeactivatedPoint={updateDeactivatedPoint} />
            <HorizontalAxis period={period} data={data} />
        </ChartWrapper>
    );
}

export default AccountBalanceChart